import Tooltip from 'Components/tooltip/Tooltip';
import React from 'react'
import { GoQuestion } from 'react-icons/go';
import styled from 'styled-components';

const CustomTextArea = styled.textarea`
  font-size: 1.4em;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  // margin-top: 0.2em;
  padding: 0;
  height: 150px;
  resize: vertical;
`;

const LabelWithTooltip = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em; /* Ajuste de espaçamento, se necessário */
`;


const TextArea = ({ name, label, value, onChange, rows, tooltip, required=false }) => (
  <>
    <LabelWithTooltip>
      <label htmlFor={name} style={{ marginRight: '0.5em' }}>{label}</label>
      {tooltip && (
        <Tooltip text={tooltip}>
          <GoQuestion size={23} />
        </Tooltip>
      )}
    </LabelWithTooltip>
    <CustomTextArea
      id={name}
      name={name}
      value={value}
      rows={rows}
      onChange={onChange}
      required={required ? true : false}
    />
  </>
);


export default TextArea;
