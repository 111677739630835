import CardGroup from "Components/card/CardGroup";
import { GoVerified } from "react-icons/go";
import { MdOutlineBlock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import { BsClipboard2Check } from "react-icons/bs";
import { AiOutlineException } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { GrDocumentDownload } from "react-icons/gr";
import {MdOutlineDataset} from "react-icons/md";
import { useDataContext } from "contexts/DataContext";


const HomeGestor = () => {
  const navigate = useNavigate();
  const { certificadosCountRegional } = useDataContext();
  let itens = [];

  const handleClick = (path) => {
    navigate(path);
  };

  let conferido =  0;
  let deferido =  0;
  let indeferido =  0;
  let recurso =  0;

if (certificadosCountRegional) {
  const certificadoCount = certificadosCountRegional[0]; // Acessa o primeiro objeto do array

  // Verifica se certificadoCount existe e é um objeto
  if (certificadoCount) {
    // Acessa as propriedades diretamente
    conferido = certificadoCount.certificadosConferidos;
    indeferido = certificadoCount.certificadosIndeferidos;
    deferido = certificadoCount.certificadosDeferidos;
    recurso = certificadoCount.certificadosRecursos;
  }
}

itens = [
  {
    id: 1,
    title: "Conferidos",
    $variant: "orange",
    type: "titleIconValue",
    value: conferido,
    icon: BsClipboard2Check,
    buttonAction: () => {
      handleClick("/conferidos");
    },
  },
  {
    id: 2,
    title: "Deferidos",
    $variant: "green",
    type: "titleIconValue",
    value: deferido,
    icon: GoVerified,
    buttonAction: () => {
      handleClick("/deferidos");
    },
  },
  {
    id: 3,
    title: "Indeferidos",
    $variant: "red",
    type: "titleIconValue",
    value: indeferido,
    icon: MdOutlineBlock,
    buttonAction: () => {
      handleClick("/indeferidos");
    },
  },
  {
    id: 4,
    title: "Recursos",
    $variant: "purple",
    type: "titleIconValue",
    value: recurso,
    icon: AiOutlineException,
    buttonAction: () => {
      handleClick("/recursos");
    },
  },
  {
    id: 6,
    title: "Relatórios",
    $variant: "blue",
    type: "titleIcon",
    icon: GrDocumentDownload,
    buttonAction: () => {
      handleClick("/relatorios");
    },
  },
  {
    id: 5,
    title: "Dashboard",
    $variant: "blue",
    type: "titleIcon",
    icon: TbLayoutDashboardFilled,
    buttonAction: () => {
      handleClick("/certificados");
    },
  },
  {
    id: 7,
    title: "Normativa",
    $variant: "blue",
    type: "titleIcon",
    icon: MdOutlineDataset,
    buttonAction: () => {
      handleClick("/relatorios");
    },
  }
];



  return (
    <>
      <CardGroup menuItems={itens} />
    </>
  );
};

export default HomeGestor;
