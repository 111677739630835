import React, { createContext, useContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('token');
    return storedToken ? storedToken : null;
  });

  const [local, setLocal] = useState(() => {
    const storedLocal = localStorage.getItem('localidade');
    return storedLocal ? storedLocal : null;
  });

  const isServidor = (typeProfile) => typeProfile === 0

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('perfil');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [typeProfile, setTypeProfile] = useState(() => {
    const storedTypes = localStorage.getItem('tipoPerfil');
    return storedTypes ? Number(storedTypes) : undefined;
  });

  const setProfileNumber = (value) => {
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      setTypeProfile(numericValue);
      localStorage.setItem('tipoPerfil', numericValue);
    }
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem('token', token);
      localStorage.setItem('tipoPerfil', Number(typeProfile));
      localStorage.setItem('perfil', JSON.stringify(user));
      localStorage.setItem('localidade', local);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('perfil');
      localStorage.removeItem('tipoPerfil');
      localStorage.removeItem('localidade');
      setTypeProfile(null);
    }
  }, [user, token, typeProfile, local]);

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken, typeProfile, setTypeProfile: setProfileNumber, local, setLocal, isServidor }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook para usar o contexto
export const useUserContext = () => useContext(UserContext);

