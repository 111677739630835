import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  getCertificados,
  deleteCertificado,
  postCertificado,
  putCertificado,
  getDocCertificado,
  putAcaoCertificado,
  getVinculos,
  getCertificadosUserId,
  getCertificadosCount,
  getCertificadosCountRegional,
  getCertificadosCountUserId,
  getRecursosCountUserId,
  getRecursos,
  putAcaoRecurso,
  getPessoaVinculo,
  getRecursosUserId,
  getRecursoBytes,
  postRecurso,
  putAtivarCertificado,
} from "../api/certifica-api";
import { useUserContext } from "./UserContext";
import Loading from "../Components/loading/Loading";
import { isNumber } from "pdfmake/src/helpers";

const DataContext = createContext();

/**
 * Componente DataProvider que fornece o contexto de dados para seus filhos.
 * @param {Object} props - As props do componente.
 * @param {React.ReactNode} props.children - Os componentes filhos.
 * @returns {JSX.Element} - O componente DataProvider.
 */
export const DataProvider = ({ children }) => {
  const { user, token, local, setLocal, typeProfile } = useUserContext();
  const [certificadoData, setCertificadoData] = useState(null);
  const [recursoId, setRecursoId] = useState(null)
  const [vinculosId, setVinculosId] = useState(null);
  const [pessoaVinculoId, setPessoaVinculoId] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const page = location.pathname.substring(0, location.pathname.lastIndexOf("/"))
  const eServido = typeProfile === 0;
  const eRh = typeProfile === 1;


  // Função para verificar se a variável é válida (não nula, undefined ou NaN)
  function isTypePerfilValid() {
    return typeProfile !== null && typeProfile !== undefined && !Number.isNaN(typeProfile);
  }


  const { data: certificadosCountRegionalAll } = useQuery(
    ["certificadosCountRegionalAll", token, typeProfile],
    () => getCertificadosCount(token, typeProfile),
    {
      enabled: !!token && (eServido != null) && !eServido && isTypePerfilValid(),
    }
  );

  const { data: certificadosCountRegional } = useQuery(
    ["certificadosCountRegional", token, local],
    () => getCertificadosCountRegional(token, local),
    {
      enabled: !!token && (eServido != null) && !eServido && isTypePerfilValid(),
    }
  );

  const { data: certificadosCountUserId } = useQuery(
    ["certificadosCountUserId", token, user.id],
    () => getCertificadosCountUserId(token, user.id),
    {
      enabled: !!token && !!user.id,
    }
  );

  const { data: pessoaVinculo } = useQuery(
    ["getPessoaVinculo", token, pessoaVinculoId],
    () => getPessoaVinculo(token, pessoaVinculoId),
    {
      enabled: !!token && !!pessoaVinculoId,
    }
  );

  const { data: certificados,
    isLoading: isLoadingCertificados,
    error: errorCertificados
  } = useQuery(
    ["certificados", token, local],
    () => getCertificados(token, local),
    {
      enabled: !!token && (eServido != null) && !eServido && isTypePerfilValid(),
    }
  );

  const { data: certificadosUserId } = useQuery(
    ["certificadosUserId", token, user.id],
    () => getCertificadosUserId(token, user.id),
    {
      enabled: !!token && !!user.id,
    }
  );

  const { data: docCertificados} = useQuery(
    ["docCertificados", token, certificadoData],
    () => getDocCertificado(token, certificadoData),
    {
      enabled: !!token && !!certificadoData,
    }
  );

  const { data: vinculos} = useQuery(
    ["vinculos", token, vinculosId],
    () => getVinculos(token, vinculosId),
    {
      enabled: !!token && !!vinculosId,
    }
  );

  const { data: recursosCountUserId } = useQuery(
    ["recursosCountUserId", token],
    () => getRecursosCountUserId(token),
    {
      enabled: !!token,
    }
  );

  const { data: recursos,
    isLoading: isLoadingRecursos,
    error: errorRecursos
  } = useQuery(
    ["recursos", token, local],
    () => getRecursos(token, local),
    {
      enabled: !!token && (eServido != null) && !eServido && (eRh != null) && !eRh ,
    }
  );

  const { data: recursosUserId } = useQuery(
    ["recursosUserId", token, user.id],
    () => getRecursosUserId(token, user.id),
    {
      enabled: !!token && !!user.id && (eServido != null) && eServido && (eRh != null) && !eRh,
    }
  );

  const { data: recursoBytes} = useQuery(
    ["recursoBytes", token, recursoId],
    () => getRecursoBytes(token, recursoId),
    {
      enabled: !!token && !!recursoId,
    }
  );
  /**
   * Lida com a mutação para postar um novo certificado.
   * @param {Object} data - Os dados do certificado a serem postados.
   */
  const mutationPostCertificado = useMutation((data) => postCertificado(token, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["certificados", token]);
      queryClient.invalidateQueries(["certificadosUserId", token, user.id]);
      navigate(page);
    },
    onError: (error) => {
      console.error("Erro ao postar certificado:", error);
    },
  });

  /**
   * Lida com a mutação para editar um novo certificado.
   * @param {Object} data - Os dados do certificado a serem editados.
   */
  const mutationPutCertificado = useMutation(({data, role=null}) => putCertificado(token, data, role), {
    onSuccess: () => {
      queryClient.invalidateQueries(["certificados", token]);
      queryClient.invalidateQueries(["certificadosUserId", token, user.id]);
      navigate(page);
    },
    onError: (error) => {
      console.error("Erro ao atualizar certificado:", error);
    },
  });

  /**
   * Lida com a mutação para deletar um certificado.
   * @param {string} id - O ID do certificado a ser deletado.
   */
  const mutationDeleteCertificado = useMutation((id) => deleteCertificado(token, id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["certificados", token]);
      queryClient.invalidateQueries(["certificadosUserId", token, user.id]);
      navigate("/certificados");
    },
    onError: (error) => {
      console.error("Erro ao deletar certificado:", error);
    },
  });


    /**
   * Lida com a mutação para editar a acao de um certificado.
   * @param {Object} data - Os dados do certificado a serem editados.
   */
    const mutationPutAcaoCertificado = useMutation(
      async ({ data, conferencia, path }) => {
        await putAcaoCertificado(token, data, conferencia);
        return path; // Retorna o caminho para ser usado na navegação
      },
      {
        onSuccess: (path) => {
          queryClient.invalidateQueries(); // Atualiza os dados no cache
          navigate(path); // Navega para o caminho desejado
        },
        onError: (error, variables) => {
          console.error("Erro ao atualizar certificado:", error);
          navigate(variables.path); // Ainda navega em caso de erro
        },
      }
    );

    /**
   * Lida com a mutação para ativar um certificado.
   * @param {Object} data - Os dados para ativação do do certificado.
   */
    const mutationPutAtivarCertificado = useMutation(
      ({data}) => putAtivarCertificado(token, data),
      {
      onSuccess: (_, variables) => {
        
      },
      onError: (error, variables) => {
        console.error("Erro ao atualizar certificado:", error);
      },
    });


    /**
   * Lida com a mutação para editar a acao de um recurso.
   * @param {Object} data - Os dados do recurso a serem editados.
   */
    const mutationPutAcaoRecurso = useMutation(
      ({ data, path }) => putAcaoRecurso(token, data),
      {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([variables.path, token]);
        navigate("/recursos");
      },
      onError: (error) => {
        console.error("Erro ao atualizar recurso:", error);
        navigate("/recursos");
      },
    });


      /**
   * Lida com a mutação para postar um novo recurso.
   * @param {Object} data - Os dados do recurso a serem postados.
   */
    const mutationPostRecurso = useMutation((data) => postRecurso(token, data), {
      onSuccess: () => {
        queryClient.invalidateQueries(["recursos", token]);
        navigate("/recursos");
      },
      onError: (error) => {
        console.error("Erro ao postar recurso:", error);
      },
    });

  const isLoading =isLoadingCertificados;
  const error = errorCertificados;
  const erroR = errorRecursos
  const isLoadingR = isLoadingRecursos
  if (isLoading || isLoadingR) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(erroR) {
    return <div>Error: {erroR.message}</div>;
  }


  const regionais =
  [
    { name: "Araguaína", id: 1 },
    { name: "Araguatins", id: 2 },
    { name: "Arraias", id: 3 },
    { name: "Colinas do Tocantins", id: 4 },
    { name: "Dianópolis", id: 5 },
    { name: "Guaraí", id: 6 },
    { name: "Gurupi", id: 7 },
    { name: "Miracema do Tocantins", id: 8 },
    { name: "Palmas", id: 9 },
    { name: "Paraíso do Tocantins", id: 10 },
    { name: "Pedro Afonso", id: 11 },
    { name: "Porto Nacional", id: 12 },
    { name: "Tocantinópolis", id: 13 }
    ];

  const localRegiao = () => {
    if (local !== 'null' && local !== null  && local !== undefined){
      const regiao = regionais.find(regiao => regiao.id === Number(local));
      return regiao ? regiao.name : null;
    }else {
      setLocal(Number(user.nregional))
      const regiao = regionais.find(regiao => regiao.id === Number(local));
      return regiao ? regiao.name : null;
    }
  }

  return (
    <DataContext.Provider
      value={{
        certificadosCountRegionalAll,
        certificadosCountRegional,
        certificadosCountUserId,
        certificados,
        certificadosUserId,
        mutationPostCertificado,
        mutationPutCertificado,
        mutationDeleteCertificado,
        docCertificados,
        mutationPutAcaoCertificado,
        mutationPutAtivarCertificado,
        vinculos,
        setCertificadoData,
        setRecursoId,
        setVinculosId,
        pessoaVinculo,
        setPessoaVinculoId,
        // certificadoRequest,
        mutationPostRecurso,
        mutationPutAcaoRecurso,
        recursosCountUserId,
        recursoBytes,
        recursosUserId,
        recursos,
        regionais,
        localRegiao,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

/**
 * Hook personalizado para usar o DataContext.
 * @returns {Object} - O valor do contexto.
 */
export const useDataContext = () => useContext(DataContext);
