import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Container from "Components/container/Container";
import CustomButton from "Components/button/Button";
import ModalGenerico from "Components/modal/ModalGenerico";
import "./cadastroCertificado.css";

import Input from "Components/form/Input";
import TextArea from "Components/form/TextArea";
import Select from "Components/form/Select";
import Checkbox from "Components/form/Checkbox";
import { useDataContext } from "contexts/DataContext";

import universidades from '../../../fakeData/universidades.json';

// TODO user vai ser uma coisa no perfil servidor e outra no rh
const CertificadoForm = ({
  formData,
  setFormData,
  user,
  handleSubmit,
  titulo,
  isEditar=true,
  data="",
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [teveAlteracao, setTeveAlteracao] = useState(false);
  const { vinculos, setVinculosId, setPessoaVinculoId, pessoaVinculo } = useDataContext();
  const [ vinculosCurrent, setVinculosCurrent ] = useState([]);


  const optionsSelect = [
    { value: "Pós-Graduação - Lato Sensu (Especialização)", label: "Pós-Graduação - Lato Sensu (Especialização)" },
    { value: "Pós-Graduação - Lato Sensu", label: "Pós-Graduação - Lato Sensu" },
    { value: "Pós-Graduação - Stricto Sensu - Mestrado", label: "Pós-Graduação - Stricto Sensu - Mestrado" },
    { value: "Pós-Graduação - Stricto Sensu - Doutorado", label: "Pós-Graduação - Stricto Sensu - Doutorado" }
  ];


  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    handleCloseModal();
    navigate(-1);
  };

  const onBeforeCancel = () => {
    if (teveAlteracao) {
      handleOpenModal();
    } else {
      handleCancel();
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "certificado") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    console.log(formData)
    setTeveAlteracao(true);
  };

  useEffect(() => {
    if (data === ""){
      setVinculosId(user.id);
    } else {
      setPessoaVinculoId(data.vinculoId)
      setVinculosCurrent(vinculos);
    }
  }, [data, user.id]);

  useEffect( () =>{
    if (vinculos){
      setVinculosCurrent(vinculos)
    }
  }, [vinculos])

  useEffect( () =>{
    if (pessoaVinculo){
      setVinculosId(pessoaVinculo.id);
    }
  }, [pessoaVinculo, setVinculosId])


  const handleSubmitEventDefault = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
      linkValidacao: checked ? formData.linkValidacao : null, // Limpa o campo caso desmarque o checkbox
    }));
  };

  let dataInicio;
  if (formData.dataInicio !== undefined && formData.dataInicio){
     dataInicio = new Date(formData.dataInicio).toISOString().split("T")[0];
  }

  let dataFim;
  if (formData.dataFim !== undefined && formData.dataFim){
    dataFim = new Date(formData.dataFim).toISOString().split("T")[0];
  }


  return (
    <>
      <Container titulo={titulo}>
        <form onSubmit={handleSubmitEventDefault}>
           <Input
            type="text"
            name="nome"
            label="Nome do curso"
            tooltip="Informe o nome do curso"
            value={formData.nome || ""}
            onChange={handleChange}
            required
          />
          <Select
            name="instituicao"
            label="Instituição"
            tooltip="Selecione a instituição de ensino"
            value={formData.instituicao || ""}
            options={universidades.map((univ) => ({
              value: univ.SIGLA,
              label: `${univ.SIGLA} - ${univ.NOME}`,
            }))}
            onChange={handleChange}
            required
          />
          <Select
            name="vinculoId"
            label="Vínculo"
            tooltip="Selecione o vínculo"
            value={formData.vinculoId || ""}
            onChange={handleChange}
            options={
              vinculosCurrent
                ? vinculosCurrent.map((vinculo) => ({
                    value: vinculo.id,
                    label: `${vinculo.numeroVinculo} - ${vinculo.categoria}`,
                  }))
                : []
            }
            required
          />
          <div className="checkbox-group">
            <Checkbox
              type="checkbox"
              name="validacaoOnline"
              label="Validação Digital ?"
              legenda="Sim"
              checked={formData.validacaoOnline || ""}
              onChange={handleCheckboxChange}
            />
            {formData.validacaoOnline && (
              <Input
                type="url"
                name="linkValidacao"
                label="Link de validação"
                tooltip="Cole o link para validação do certificado"
                value={formData.linkValidacao || ""}
                onChange={handleChange}
                required={formData.validacaoOnline} // obrigatório apenas se o checkbox estiver marcado
              />
            )}
          </div>
          <Select
            name="tipoQualificacao"
            label="Tipo de Qualificação"
            tooltip="Informe o tipo de qualificação do certificado"
            value={formData.tipoQualificacao || ""}
            options={optionsSelect}
            onChange={handleChange}
            required
          />
          <Input
            type="number"
            label="Carga Horária"
            name="cargaHoraria"
            tooltip="Informe a carga horária em horas"
            value={formData.cargaHoraria || ""}
            onChange={handleChange}
            required
          />
         <div className="inline-inputs">
            <div>
              <Input
                type="date"
                label="Data de Início"
                name="dataInicio"
                value={dataInicio || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <Input
                type="date"
                label="Data de Fim"
                name="dataFim"
                value={dataFim || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
            <div>
              <Input
                type="file"
                label="Anexo"
                accept=".pdf"
                name="certificado"
                tooltip="Insira o anexo com o certificado para avaliação"
                onChange={handleChange}
                required={isEditar ? false : true}
              />
            </div>
          <TextArea
            maxLength="255"
            name="observacao"
            label="Observação"
            rows="4"
            value={formData.observacao || ""}
            onChange={handleChange}
          />
          <div
            style={{
              display: "flex",
              gap: "30px",
              justifyContent: "space-between",
              width: "60%",
              marginLeft: "20%",
            }}
          >
            <CustomButton type="submit" >
              {!isEditar ? "Cadastrar" : "Atualizar"}
            </CustomButton>
            <CustomButton $variant="cancel" onClick={onBeforeCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Container>
      <ModalGenerico
        isOpen={isModalOpen}
        text="Se você sair, perderá seu progresso. Tem certeza que deseja sair?"
        onCancel={handleCancel}
        onProceed={handleCloseModal}
      />
    </>
  );
};

export default CertificadoForm;
