import React from "react";
import styled from "styled-components";

import CustomCard from "./CustomCard";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 25px;
`;

function CardGroup({ menuItems }) {
  return (
    <Container>
      {menuItems &&
        menuItems.map((item) => {
          return (
           <CustomCard key={item.id} item={item} type={item.type} $variant={item.$variant} style={{marginTop: '100px'}} icon={item.icon}/>

          );
        })}
    </Container>
  );
}

export default CardGroup;
