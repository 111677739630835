import React from 'react'
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: clamp(400px, 60%, 1200px);
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 10.1px 0px rgba(0, 0, 0, 0.15);
`;

const Titulo = styled.div`
  font-size: 2em;
  color: #111;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
`;

const Container = ({ children, titulo }) => {
  return (
    <StyledContainer>
      {titulo && <Titulo>{titulo}</Titulo>}
      {children}
    </StyledContainer>
  )
}

export default Container
