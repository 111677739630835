import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineSearch } from "react-icons/md";
import styled from "styled-components";
import StatusField from "Components/informationsField/StatusField";
import TabelaLoader from "Components/table/TabelaLoader";
import { useDataContext } from "contexts/DataContext";

const Link = styled.p`
  &:hover {
    color: #04a3fb;
    cursor: pointer;
  }
`;

const ListaCertificados = ({ status }) => {
  const navigate = useNavigate();
  const { certificados, setPessoaVinculoId, pessoaVinculo } = useDataContext();
  const [certificadoFiltrado, setCertificadoFiltrado] = useState([]);
  const [certificadosCurrent, setCertificadosCurrent] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const clickDetalhes = useCallback(
    (id) => {
      const data = certificadosCurrent.find((certificado) => certificado.id === id);
      if (data != null) {
        navigate("detalhes", { state: { data } });
      }
    },
    [navigate, certificadosCurrent]
  );

  const handleClickUsuario = useCallback(
    (nome) => {
      const data = certificadosCurrent.filter(
        (certificado) => certificado.perfil === nome
      );
      navigate("/tabela-usuario", { state: { data } });
    },
    [certificadosCurrent, navigate]
  );

  const filterAndProcessCertificados = useCallback(async () => {
    setIsProcessing(true);

    let filteredCertificados = [];
    let selectedCertificados = [];

    switch (status) {
      case 0:
        selectedCertificados = certificados?.certificadosPendentes || [];
        break;
      case 2:
        selectedCertificados = certificados?.certificadosConferidos || [];
        break;
      case 3:
        selectedCertificados = certificados?.certificadosIndeferidos || [];
        break;
      case 4:
        selectedCertificados = certificados?.certificadosDeferidos || [];
        break;
      case 6:
        selectedCertificados = certificados?.certificadosNaoValidos || [];
        break;
      case 7:
        selectedCertificados = certificados?.certificadosRefutados || [];
        break;
      default:
        selectedCertificados = [];
        break;
    }

    setCertificadosCurrent(selectedCertificados);

    if (selectedCertificados.length > 0) {
      filteredCertificados = await Promise.all(
        selectedCertificados.map(async (certificado, index) => {
          await setPessoaVinculoId(certificado.vinculoId);

          if (!pessoaVinculo) {
            return null;
          }

          const vinculoNumero = pessoaVinculo.vinculo.id || 1;
          const vinculoNome = pessoaVinculo.vinculo.setorNome;

          return {
            id: index + 1,
            regional: pessoaVinculo.vinculo.regional.sre,
            nome: (
              <Link onClick={() => handleClickUsuario(certificado.nome)}>
                {certificado.nome}
              </Link>
            ),
            certificado: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StatusField status={certificado.status} />
              </div>
            ),
            vinculo: `${vinculoNumero} - ${vinculoNome}`,
            acao: (
              <MdOutlineSearch
                size={25}
                onClick={() => clickDetalhes(certificado.id)}
              />
            ),
          };
        })
      );

      const validCertificados = filteredCertificados.filter((cert) => cert !== null);
      setCertificadoFiltrado(validCertificados);
    }

    setIsProcessing(false);
  }, [certificados, clickDetalhes, handleClickUsuario, pessoaVinculo, setPessoaVinculoId, status]);

  useEffect(() => {
    if (certificados && Object.keys(certificados).length > 0 && !isProcessing) {
      filterAndProcessCertificados();
    }
  }, [certificados, status, filterAndProcessCertificados, isProcessing]);

  const certificadosHeader = [
    "ID",
    "Regional",
    "Nome",
    "Certificado",
    "Vínculo",
    "Ação",
  ];
  const fieldsToSearch = ["nome", "certificado", "regional", "vinculo"];

  return (
    <>
      <br />
      <TabelaLoader
        data={certificadoFiltrado}
        header={certificadosHeader}
        paginated={true}
        search={fieldsToSearch}
      />
    </>
  );
};

export default ListaCertificados;

