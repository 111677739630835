import React, { useEffect, useState } from "react";
import { PiCertificate } from "react-icons/pi";
import { AiOutlineException } from "react-icons/ai";
import { AiOutlineHistory } from "react-icons/ai";
import { LuFilePlus2 } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import CardGroup from "Components/card/CardGroup";
import {MdOutlineDataset} from "react-icons/md";
import { useDataContext } from "contexts/DataContext";

const HomeServidor = () => {
  const navigate = useNavigate();
  const { typeProfile } = useUserContext();
  const { certificadosCount, certificadosCountUserId,recursosCountUserId } = useDataContext();
  const [certificadosCountCurrent, setCertificadosCountCurrent] = useState(0);
  const [recursosCountCurrent, setRecursosCountCurrent] = useState(0);

  useEffect(() => {
    if (typeProfile === 4) {
      setCertificadosCountCurrent(certificadosCount);
    } else {
      setCertificadosCountCurrent(certificadosCountUserId);
      setRecursosCountCurrent(recursosCountUserId)
    }
  }, [typeProfile, certificadosCount, certificadosCountUserId,recursosCountUserId]);

  // let recursosCount =  {"count":0};
  // if (certificadosCount){
  //   for (let index = 0; index < certificadosCount.length; index++) {
  //     switch (certificadosCount[index]['status']) {
  //       case "RECURSO":
  //         recursosCount = certificadosCount[index];
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }

  const handleClick = (path) => {
    navigate(path);
  };

  const itens = [
    {
      id: 0,
      title: "Cadastrar",
      $variant: "green",
      type: "titleIcon",
      icon: LuFilePlus2,
      buttonAction: () => {
        handleClick("/certificados/cadastrar");
      },
    },
    {
      id: 1,
      title: "Certificados",
      $variant: "blue",
      type: "titleIconValue",
      value: certificadosCountCurrent,
      icon: PiCertificate,
      buttonAction: () => {
        handleClick("/certificados");
      },
    },
    {
      id: 2,
      title: "Recursos",
      $variant: "purple",
      type: "titleIconValue",
      value: recursosCountCurrent,
      icon: AiOutlineException,
      buttonAction: () => {
        handleClick("/recursos");
      },
    },
    ,
    {
      id: 4,
      title: "Historico",
      $variant: "blue",
      type: "titleIcon",
      icon: AiOutlineHistory,
      buttonAction: () => {
        handleClick("/relatorios");
      },
    },
    {
      id: 3,
      title: "Normativa",
      $variant: "blue",
      type: "titleIcon",
      icon: MdOutlineDataset,
      buttonAction: () => {
        handleClick("/normativas");
      },
    }
  ];

  return <CardGroup menuItems={itens} />;
};

export default HomeServidor;

