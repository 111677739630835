import React from 'react';
import { GoQuestion } from 'react-icons/go';
import Tooltip from 'Components/tooltip/Tooltip';
import styled from 'styled-components';

const CustomCheckbox = styled.input`
  width: 5%;
  border: 1px solid transparent;
  border-radius: 0px;
  background-color: #ebebeb;
`;

const LabelWithTooltip = styled.div`
  display: flex;
  align-items: center;
`;

const Checkbox = ({ type, label, name, value, onChange, tooltip, required = false , legenda, checked}) => (
  <>
    <LabelWithTooltip disabled={true}>
      <label htmlFor={name} style={{ marginRight: '0.5em' }}>{label}</label>
      {tooltip && (
        <Tooltip text={tooltip}>
          <GoQuestion size={23} />
        </Tooltip>
      )}
    </LabelWithTooltip>
     <div className="inline-checkbox">
          <CustomCheckbox
               type={type}
               id={name}
               name={name}
               checked={checked}
               onChange={onChange}
               required={required ? true : false}
          />
          {legenda && (<label style={{margin:'0px'}}>Sim</label>)}
     </div>
  </>
);

export default Checkbox;
