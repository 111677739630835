import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineSearch } from "react-icons/md";
import styled from "styled-components";

import Tabela from "Components/table/Tabela";
import StatusField from "Components/informationsField/StatusField";
import { useUserContext } from "contexts/UserContext";
import { useDataContext } from "contexts/DataContext";

const Recursos = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { certificados, certificadosUserId, recursosUserId, recursos } = useDataContext();
  const [certificadosCurrent, setCertificadosCurrent] = useState([]);
  const [RecursosCurrent, setRecursosCurrent] = useState([]);
  const { typeProfile } = useUserContext();
  const [recursosResultado, setRecursosResultado] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const isServidor = typeProfile === 0;
  const [isLoaded, setIsLoaded] = useState(false);


  useEffect(() => {
    if (typeProfile === 0) {
      setCertificadosCurrent(certificadosUserId);
      setRecursosCurrent(recursosUserId);
    }else if(typeProfile === 1){
      
    }else {
      const todosRecursos = [
        ...recursos.recursosPendentes,
        ...recursos.recursosReprovados,
        ...recursos.recursosAprovados
      ];
      const todosCertificados = [
        ...certificados.certificadosRecursos,
        ...certificados.certificadosDeferidos,
        ...certificados.certificadosIndeferidos
      ];

      setRecursosCurrent(todosRecursos);
      setCertificadosCurrent(todosCertificados);
    }
  }, [typeProfile, certificadosUserId, certificados, isLoaded,recursosUserId,state?.refresh]);


  const clickDetalhes = useCallback(
    (id) => {
      const data = RecursosCurrent.find((recurso) => recurso.id === id);
      if (data != null){
        navigate("detalhes", { state: { data } });
      }
    },
    [navigate, RecursosCurrent]
  );

  const filtrarRecursosDeCertificado = useCallback(async () => {
    const filteredRecursos = RecursosCurrent;
    const filteredCertificados = certificadosCurrent;

    if (filteredRecursos && filteredCertificados) {
      const certificadoMap = filteredCertificados.reduce((map, certificado) => {
        map[certificado.id] = certificado.nome; 
        return map;
      }, {});
  
      const processedRecursos = await Promise.all(
        filteredRecursos.map(async (recurso, index) => {
          return {
            id: index + 1,
            nome: certificadoMap[recurso.certificadoId] || "Certificado não encontrado",
            status: <StatusField status={recurso.status} />,
            acao: (
              <MdOutlineSearch
                size={25}
                onClick={() => clickDetalhes(recurso.id)}
              />
            ),
          };
        })
      );
      
      console.log("RECURSO PROCESSADO", processedRecursos)
      setRecursosResultado(processedRecursos);
    }
  
    setIsProcessing(false);
  }, [certificadosCurrent, clickDetalhes]);

  useEffect(() => {
    if (
      (!isProcessing &&
        ((Object.keys(certificadosUserId ?? {}).length > 0 && Object.keys(recursosUserId ?? {}).length > 0) || (Object.keys(certificados ?? {}).length > 0) && (Object.keys(recursos ?? {}).length > 0)))
      ) {
      filtrarRecursosDeCertificado();
    }
  }, [certificados, certificadosUserId, filtrarRecursosDeCertificado, isProcessing,recursosUserId]);

  const recursoHeader = ["ID", "Certificado","Status", "Ação"];
  const searchTerm = ["nome"];

  return (
    <>
      <Tabela
        data={recursosResultado}
        header={recursoHeader}
        paginated={true}
        search={searchTerm}
        hasAdd={isServidor}
      />
    </>
  );
};

export default Recursos;
