//Bibliotecas



//----------------------------------------------------------------

//Funções

/**
 * 
 * @param {Any} file Arquivo.
 * @returns {String} Retorna o arquivo códificado em base64.
 */
export function codificar(file){
     return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
}