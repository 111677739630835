import React, { useState } from 'react';
import './modalGenerico.css';
import CustomButton from 'Components/button/Button';
import { Notify, ToastType } from "Components/notify/notify";

const ModalAtivarCertificado = ({isOpen, onCancel, onConfirm, status}) => {
     const [deferido, setDeferido] = useState();
     const [diario, setDiario] = useState('');
     const [portaria, setPortaria] = useState('');
     const [diarioError, setDiarioError] = useState(false);
     const [portariaError, setPortariaError] = useState(false);
     const [seletTipoErro, setSeletTipoError] = useState(false);

     const handlePortariaInsert = (event) => {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }else{
        if (event.target.name === 'numeroDiario'){
          setDiarioError(false);
        };
        if (event.target.name === 'numeroPortaria'){
          setPortariaError(false);
        };
      };
     };

     const handleConfirm = () => {
        onConfirm(diario, portaria);
        if (diario === '') {
          setDiarioError(true);
        };
        if (portaria === '') {
          setPortariaError(true);
        };
     };

     const cancelar = () => {
      setDiario('');
      setPortaria('');
      onCancel();
     };

     if (!isOpen) return null;

     return (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Aceitação de Certificado</h2>
              <br></br>
              <p className='texto2'>
                Para torna esse certificado vigente, insira os números do Diário e números da Portaria.
              </p>

              <div>
                <label>Número do Diário:
                { diarioError? (
                  <p style={{ color: 'red' }}>
                    Adicione o número do Diário!
                  </p>):("")
                }
                </label>
                <input
                  name='numeroDiario'
                  type="text"
                  value={diario}
                  onKeyPress={handlePortariaInsert}
                  onChange={(e) => setDiario(e.target.value)}
                  placeholder="Digite apenas números"
                />
              </div>
              <br></br>
              <div>
                <label>Número de Portaria:
                { portariaError? (
                  <p style={{ color: 'red' }}>
                    Adicione o número de Portaria!
                  </p>):("")
                }
                </label>
                <input
                  name='numeroPortaria'
                  type="text"
                  value={portaria}
                  onKeyPress={handlePortariaInsert}
                  onChange={(e) => setPortaria(e.target.value)}
                  placeholder="Digite apenas números"
                />
              </div>
              <br></br>
              <CustomButton onClick={handleConfirm}>Confirmar</CustomButton>
              <CustomButton $variant="cancel" onClick={cancelar}>Cancelar</CustomButton>
            </div>
          </div>
        );
};

export default ModalAtivarCertificado;