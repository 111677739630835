import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoQuestion } from "react-icons/go";

import { useUserContext } from "contexts/UserContext";
import CustomButton from "Components/button/Button";
import Container from "Components/container/Container";
import Tooltip from "Components/tooltip/Tooltip";
import ModalGenerico from "Components/modal/ModalGenerico";
import { useDataContext } from "contexts/DataContext";
import { Notify, ToastType } from "Components/notify/notify";

const RecursoCadastro = () => {
  const navigate = useNavigate();
  const { user , isServidor, typeProfile} = useUserContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const{ certificadosUserId, mutationPostRecurso } = useDataContext();
  const[certificados, setCertificados] = useState([]);
  const [base64, setBase64] = useState('');
  const iconSize = 20;

  useEffect(() => {

    if(isServidor(typeProfile)){
      if (certificadosUserId) {
        const certificadosIndeferidos = certificadosUserId.filter(
          (certificado) => certificado.status === "INDEFERIDO"
        );

    
        // Atualiza o estado com os resultados do filtro ou um array vazio
        setCertificados(certificadosIndeferidos.length > 0 ? certificadosIndeferidos : []);
      }
    }else{
      navigate("/home");
    }
    
  }, [certificadosUserId]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [formData, setFormData] = useState({
    anexoJustificativa: null,
    justificativa: "",
    certificado: "",
  });



  const retornaSeTemCampoPreenchido = () => {
    return Object.values(formData).some((value) => value !== "" && value !== null && value !== undefined);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "anexoJustificativa") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCancel = () => {
    handleCloseModal();
    navigate(-1);
  };

  const onBeforeCancel = () => {
    if (retornaSeTemCampoPreenchido()) {
      handleOpenModal();
    } else {
      handleCancel();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Previne o envio padrão do formulário

    const file = formData.anexoJustificativa;
    console.log("TIPO DE ARQUIVO", file.type)
    if (file.type !== 'application/pdf') {
      Notify({
        message: `O Anexo do recurso deve estar no formato PDF!`,
        type: ToastType.Error,
        duration:3000,
      });
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      Notify({
        message: `O Anexo do Recurso não pode ser maior que 5 MB!`,
        type: ToastType.Error,
        duration:3000,
      });
      return;
    }

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    try {
      const base64 = await getBase64(file);

      const novoRecurso = {
        ...formData,
        anexoJustificativa: base64,
      };

      console.log(novoRecurso)

      await mutationPostRecurso.mutateAsync(novoRecurso);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Container titulo="Cadastro de Recurso">
        <form onSubmit={handleSubmit}>
          <div>
            <div>
              <div className="label-icon">
                <label htmlFor="tipo-qualificacao">
                  Selecione o certificado indeferido:
                </label>
                <Tooltip text="Informe o tipo de qualificação do certificado">
                  <GoQuestion size={iconSize} />
                </Tooltip>
              </div>
              <select
                name="certificado"
                value={formData.certificado}
                onChange={handleChange}
                required
              >
                <option value="">Selecione</option>
                {certificados ? (
                  certificados.map((opcao) => (
                    <option key={opcao.id} value={opcao.id}>
                      {opcao.nome}
                    </option>
                  ))
              ):("")}
              </select>
            </div>
            <div className="label-icon">
              <label htmlFor="anexo">Anexo do recurso:</label>
              <Tooltip text="Insira o anexo com o certificado para avaliação">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <input
              type="file"
              name="anexoJustificativa"
              className="file-input"
              accept=".pdf"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <div className="label-icon">
              <label htmlFor="justificativa">Justificativa:</label>
              <Tooltip text="Insira o anexo com o certificado para avaliação">
                <GoQuestion size={iconSize} />
              </Tooltip>
            </div>
            <textarea
              maxLength="255"
              name="justificativa"
              rows="4"
              value={formData.justificativa}
              onChange={handleChange}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              justifyContent: "space-between",
              width: "60%",
              marginLeft: "20%",
            }}
          >
            <CustomButton type="submit">Cadastrar</CustomButton>
            <CustomButton $variant="cancel" onClick={onBeforeCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      </Container>
      <ModalGenerico
        isOpen={isModalOpen}
        text="Se você sair, perderá seu progresso. Tem certeza que deseja sair?"
        onCancel={handleCancel}
        onProceed={handleCloseModal}
      />
    </>
  );
};

export default RecursoCadastro;
