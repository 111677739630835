import React, { useState, navigate} from "react";
import {
  MdOutlineHome,
  MdOutlineDataset,
  MdOutlineInfo,
  MdOutlineVerified,
  MdOutlineBlock,
  MdOutlinePendingActions,
  MdSupportAgent,
  MdLogout
} from "react-icons/md";
import { GoVerified } from "react-icons/go";
import {TbLayoutDashboardFilled} from 'react-icons/tb';
import { LuFilePlus2 } from "react-icons/lu";
import {MdOutlineCancel} from 'react-icons/md';
import { FaExchangeAlt } from "react-icons/fa";
import { GrDocumentDownload } from "react-icons/gr";
import {  BsClipboard2Check } from "react-icons/bs";
import logoSVG from '../../../Assets/logo_certifica.svg';
import { PiCertificate } from "react-icons/pi";
import {AiOutlineException, AiOutlineHistory} from "react-icons/ai";
import { useUserContext } from "contexts/UserContext";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

const StyledSidebar = styled.div`
  width: ${(props) => (props.$expanded ? "250px" : "100px")};
  height: 96.5vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  margin: 25px;
  flex-direction: column;
  transition: width 0.3s ease;
  transition: box-shadow 0.3s ease;
  border-right: 1px solid #dee2e6;
  box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.3);
`;

const SidebarHeader = styled.div`
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  height: 70px;
  border-radius: 15px;
  padding: 30px 0px;
  margin-bottom: 10px;
  box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s ease;
  transition: box-shadow 0.5s ease;

  &:hover {
    color: #ffffff;
    background-color: #00acc1;
    box-shadow: 6px 4px 6px rgba(0, 0, 0, 0.4);
  }
`;

const SidebarBrand = styled.div`
  display: flex;
  align-items: center;
  font-size: 27px;
  font-weight: bold;
  color: black;
  display: ${(props) => (props.$expanded ? "inline" : "none")};
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-x: hidden;
  color: #666;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #00acc1;
  }
  &::-webkit-scrollbar-thumb {
    background: #00acc1;
    &:hover {
      background: #00acc1;
    }
  }
  scrollbar-width: thin;
  scrollbar-color: #0098ab #00acc1;
`;

const SidebarFooter = styled.div`
  color: #666;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 15px 15px;
  border-top: 1px solid #ededed;
`;

const SidebarItemContainer = styled.li`
  padding: 10px;
  margin: 10px 10px 10px 30px;
  font-size: 1.2em;
  text-align: left;
  cursor: pointer;
  display: flex;
  background-color: ${(props) => (props.$selected ? "#00acc1" : "inherit")};
  color: ${(props) => (props.$selected ? "white" : "inherit")};
  border-radius: 8px;
  justify-content: center;
  justify-content: ${(props) => (props.$expanded ? "flex-start" : "center")};
  transition: background-color, color 0.3s;

  &:hover {
    background-color: #00acc1;
    color: white;
  }
`;

const SidebarSubItemContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  display: ${(props) => (props.$expanded ? "block" : "none")};
`;

const SidebarItemIcon = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const SidebarItemText = styled.span`
  display: ${(props) => (props.$expanded ? "inline" : "none")};
  font-weight: bold;
  align-items: center;
`;

const SidebarSectionTitle = styled.li`
  padding: 10px;
  color: #00acc1;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: left;
`;

const Spacer = styled.div`
  height: 1px;
  background-color: #00acc1;
  margin: 35px 20px 20px 20px;
`;

const LogoutPopup = styled.div`
  position: absolute;
  bottom: 20px;
  right: -150px;
  // top: 0px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 10;

  button {
    background: none;
    border: none;
    color: black;
    font-size: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: #f0f0f0;
    }

    svg {
      margin-left: 10px;
    }
  }
`;

const SidebarItem = ({ icon, title, texto, path, children, expanded, selectedItem, isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    if (children) {
      setIsExpanded(!isExpanded);
    } else if (path === "") {
      setIsOpen(!isOpen);
    } else {
      if (path === "/login") {
        localStorage.clear();
      }
      navigate(path);
    }
  };
  return (
    <div>
      {title &&
        (expanded ? (
          <SidebarSectionTitle>{title}</SidebarSectionTitle>
        ) : (
          <Spacer />
        ))}
      <SidebarItemContainer onClick={handleToggle} $expanded={expanded} $selected={selectedItem === path}>
        <SidebarItemIcon>{icon}</SidebarItemIcon>
        <SidebarItemText $expanded={expanded}>{texto}</SidebarItemText>
      </SidebarItemContainer>
      {children && (
        <SidebarSubItemContainer $expanded={isExpanded}>
          {children.map((child, index) => (
            <SidebarSubItem key={index} {...child} expanded={expanded} selectedItem={selectedItem} />
          ))}
        </SidebarSubItemContainer>
      )}
    </div>
  );
};

const SidebarSubItem = ({ icon, texto, path, expanded, selectedItem }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <SidebarItemContainer onClick={handleClick}  $selected={selectedItem === path}>
      <SidebarItemIcon>{icon}</SidebarItemIcon>
      <SidebarItemText $expanded={expanded}>{texto}</SidebarItemText>
    </SidebarItemContainer>
  );
};

const Sidebar = ({ expanded, onToggle }) => {
  const { typeProfile } = useUserContext();
  const location = useLocation();
  const selectedItem = location.pathname;

  const handleToggle = () => {
    onToggle();
  };

  const typeMap = {
    0: "Servidor",
    1: "RH - Regional",
    2:"Gestor de Quadro",
    3: "Especialista",
    4: "Administrador",
  }

  let sidebarItens = [];

  if (typeProfile === 0) {
    sidebarItens = [
      {
        icon: <LuFilePlus2 size={30} />,
        texto: "Cadastrar",
        path: "/certificados/cadastrar",
      },
      {
        icon: <PiCertificate size={30} />,
        texto: "Certificados",
        path: "/certificados",
      },
      {
        icon: <AiOutlineException size={30} />,
        texto: "Recursos",
        path: "/recursos",
      },
      {
        icon: <AiOutlineHistory size={30} />,
        texto: "Historico",
        path: "/historico",
      },
    ];
  } else if (typeProfile === 1) {
    sidebarItens = [
      {
        icon: <PiCertificate size={30} />,
        texto: "Certificados",
        children: [
          {
            icon: <MdOutlinePendingActions size={30} />,
            texto: "Pendentes",
            path: "/pendentes",
          },
          {
            icon: <BsClipboard2Check size={30} />,
            texto: "Conferidos",
            path: "/conferidos",
          },
          {
            icon: <MdOutlineBlock size={30} />,
            texto: "Não Validados",
            path: "/naoValidados",
          },
          {
            icon: <MdOutlineCancel size={30} />,
            texto: "Refutados",
            path: "/refutados",
          }
        ],
      },
      {
        icon: <GrDocumentDownload  size={30} />,
        texto: "Relatórios",
        path:"/relatorios",
      },
      {
        icon: <TbLayoutDashboardFilled  size={30} />,
        texto: "Dashboard",
        path:"/dashboard",
      },

    ]
  } else if (typeProfile === 2 || typeProfile === 3) {
    sidebarItens = [
      {
        icon: <PiCertificate size={30} />,
        texto: "Certificados",
        children: [
          {
            icon: <BsClipboard2Check size={30} />,
            texto: "Conferidos",
            path: "/conferidos",
          },
          {
            icon: <GoVerified size={30} />,
            texto: "Deferidos",
            path: "/deferidos",
          },
          {
            icon: <MdOutlineBlock size={30} />,
            texto: "Indeferidos",
            path: "/indeferidos",
          }
        ],
      },
      {
        icon: <AiOutlineException size={30} />,
        texto: "Recursos",
        path: "/recursos",
      },
      {
        icon: <GrDocumentDownload  size={30} />,
        texto: "Relatórios",
        path:"/relatorios",
      },
      {
        icon: <TbLayoutDashboardFilled  size={30} />,
        texto: "Dashboard",
        path:"/dashboard",
      },

    ]
  }

  return (
    <StyledSidebar $expanded={expanded && true}>
      <SidebarHeader onClick={handleToggle}>
        <img className="titulo-logo" src={logoSVG} alt="Logo Certifica"/>
        <SidebarBrand $expanded={expanded}>Certifica</SidebarBrand>
      </SidebarHeader>
      <MenuList>
        <SidebarItem
          icon={<MdOutlineHome size={30} />}
          texto="Página Inicial"
          path="/home"
          expanded={expanded}
          typeProfile={typeProfile}
          selectedItem={selectedItem}
        />

        {sidebarItens.map((item, index) => (
          <SidebarItem key={index} {...item} expanded={expanded} selectedItem={selectedItem}/>
        ))}

        <SidebarItem
          icon={<MdOutlineDataset size={30} />}
          texto={"Normativas"}
          path="/normativas"
          expanded={expanded}
          selectedItem={selectedItem}
        />

        <SidebarItem
          icon={<MdOutlineInfo size={30} />}
          texto={"Sobre"}
          path="/sobre"
          expanded={expanded}
          selectedItem={selectedItem}
        />

      </MenuList>
      <SidebarFooter>
        <SidebarItem
          icon={<MdSupportAgent size={30} />}
          texto="Suporte"
          path="/suporte"
          expanded={expanded}
          selectedItem={selectedItem}
        />
        <SidebarItem
          icon={<FaExchangeAlt size={30} />}
          texto="Perfil"
          path="/perfil"
          expanded={expanded}
          selectedItem={selectedItem}
        />
        {typeProfile === 1 && (
          <SidebarItem
            icon={<FaExchangeAlt size={30} />}
            texto="Regional"
            path="/selecao-regional"
            expanded={expanded}
            selectedItem={selectedItem}
          />
        )}
        <SidebarItem
          icon={<MdLogout size={30} />}
          texto="Logout"
          expanded={expanded}
          selectedItem={selectedItem}
          path="/login"
        />
      </SidebarFooter>
    </StyledSidebar>
  );
};

export default Sidebar;
