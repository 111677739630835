import React, { useState } from "react";

import { useUserContext } from "contexts/UserContext";
import CertificadoForm from "./CertificadoForm";
import { useDataContext } from "contexts/DataContext";
import { Notify, ToastType } from "Components/notify/notify";

const CertificadoCadastrar = () => {
  const { user } = useUserContext();
  const { mutationPostCertificado } = useDataContext();

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getMonth() + 1
  }/${currentDate.getFullYear()}`;

  const [formData, setFormData] = useState({
    nome: "",
    dataInicio: "",
    dataFim: "",
    instituicao: "",
    tipoQualificacao: "",
    cargaHoraria: "",
    certificado: null,
    observacao: "",
    status: 0,
    motivo: "",
    ativo: false,
    vinculoId: "",
    dataCadastro: formattedDate,
    dataConferencia: null,
    quemConferiu: null,
    dataDeferimentoOuIndeferimento: null,
    quemDeferiuOuIndeferiu: null,
    validacaoOnline: false,
    linkValidacao: "",
    resposta: null,
    regional: user.regional,
    pessoa: user,
  });

  const handleSubmit = async () => {
    const file = formData.certificado;

    // Verificar se o Arquivo é um pdf
    if (file.type !== 'application/pdf') {
      Notify({
        message: `O Anexo do recurso deve estar no formato PDF!`,
        type: ToastType.Error,
        duration:3000,
      });
      return;
    }
    
    // Verificar se o arquivo tem menos que 5 MB
    if (file.size > 5 * 1024 * 1024) {
      Notify({
        message: `O Anexo do Recurso não pode ser maior que 5 MB!`,
        type: ToastType.Error,
        duration:3000,
      });
      return;
    }

    // Usa uma Promise para aguardar a leitura do arquivo
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };


    // Função demora a retorna a base64
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const base64String = reader.result.split(",")[1];
    //     setBase64(base64String);
    //   };
    //   reader.readAsDataURL(file);
    // }

    try {
      const base64 = await getBase64(file);

      const novoCertificado = {
        ...formData,
        vinculoId: parseInt(formData.vinculoId, 10),
        dataInicio: new Date(formData.dataInicio).toISOString(),
        dataFim: new Date(formData.dataFim).toISOString(),
        certificado: base64,
      };

      await mutationPostCertificado.mutateAsync(novoCertificado);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <CertificadoForm
      formData={formData}
      setFormData={setFormData}
      user={user}
      handleSubmit={handleSubmit}
      isEditar={false}
      titulo="Cadastrar Certificado"
    />
  );
};

export default CertificadoCadastrar;
