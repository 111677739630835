import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineSearch } from "react-icons/md";
import styled from "styled-components";
import { useUserContext } from "contexts/UserContext";
import StatusField from "Components/informationsField/StatusField";
import TabelaLoader from "Components/table/TabelaLoader";
import { useDataContext } from "contexts/DataContext";

const Link = styled.p`
  &:hover {
    color: #04a3fb;
    cursor: pointer;
  }
`;

const ListaCertificadosUsuario = () => {
  const navigate = useNavigate();
  const { typeProfile } = useUserContext();
  const { certificadosUserId, setPessoaVinculoId, pessoaVinculo } = useDataContext();
  const [certificadoFiltrado, setCertificadoFiltrado] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Atualiza os certificados ao carregar ou quando mudar o tipo de perfil
  useEffect(() => {
    if (typeProfile === 0 && certificadosUserId) {
      setIsLoading(false);
    }
  }, [typeProfile, certificadosUserId]);

  const clickDetalhes = useCallback(
    (id) => {
      const data = certificadosUserId.find((certificado) => certificado.id === id);
      navigate("detalhes", { state: { data } });
    },
    [certificadosUserId, navigate]
  );

  const handleClickUsuario = useCallback(
    (nome) => {
      const data = certificadosUserId.filter(
        (certificado) => certificado.nome === nome
      );
      navigate("/tabela-usuario", { state: { data } });
    },
    [certificadosUserId, navigate]
  );

  const filterAndProcessCertificados = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const processedCertificados = await Promise.all(
        certificadosUserId.map(async (certificado, index) => {
          await setPessoaVinculoId(certificado.vinculoId);

          if (!pessoaVinculo) {
            throw new Error("Pessoa vínculo não encontrado.");
          }

          const vinculoNumero = pessoaVinculo.vinculo.id;
          const vinculoNome = pessoaVinculo.vinculo.categoria;

          return {
            id: index + 1,
            regional: pessoaVinculo.vinculo.regional.sre,
            nome: (
              <Link onClick={() => handleClickUsuario(certificado.nome)}>
                {certificado.nome}
              </Link>
            ),
            certificado: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StatusField status={certificado.status} />
              </div>
            ),
            vinculo: `${vinculoNumero} - ${vinculoNome}`,
            acao: (
              <MdOutlineSearch
                size={25}
                onClick={() => clickDetalhes(certificado.id)}
              />
            ),
          };
        })
      );

      setCertificadoFiltrado(processedCertificados);
    } catch (err) {
      setError(err.message || "Erro ao processar certificados.");
    } finally {
      setIsLoading(false);
    }
  }, [certificadosUserId, handleClickUsuario, clickDetalhes, pessoaVinculo, setPessoaVinculoId]);

  useEffect(() => {
    if (certificadosUserId && certificadosUserId.length > 0) {
      filterAndProcessCertificados();
    }
  }, [certificadosUserId, filterAndProcessCertificados]);

  const certificadosHeader = [
    "ID",
    "Regional",
    "Nome",
    "Status",
    "Vínculo",
    "Ação",
  ];

  const fieldsToSearch = ["nome", "status", "regional", "vinculo"];

  if (isLoading) {
    return <p>Carregando certificados...</p>;
  }

  if (error) {
    return <p>Erro: {error}</p>;
  }

  return (
    <>
      <br />
      <TabelaLoader
        data={certificadoFiltrado}
        header={certificadosHeader}
        paginated={true}
        search={fieldsToSearch}
      />
    </>
  );
};

export default ListaCertificadosUsuario;
