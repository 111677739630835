
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { BsEnvelope, BsPerson } from "react-icons/bs";
import styled from "styled-components";


import { useUserContext } from "contexts/UserContext";
import { useDataContext } from "contexts/DataContext";
import { useNavigate } from "react-router-dom";


const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const StyledIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;

  color: black;
  background-color: #ffffff;

  border-radius: 30px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &:hover {

    color: #ededed;
    background-color: #00acc1;

    cursor: pointer;
  }
`;


const StyledIconMessage = styled.div`
  position: absolute;
  right: ${(props) => (props.$isvisible ? "105%" : "30%")};
  top: 0;
  height: 60px;
  width: 60px;
  color: black;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: right 0.2s;

  &:hover {
    color: #ededed;
    background-color: #00acc1;
    cursor: pointer;
  }
`;

const StyledProfileBar = styled.div`
  padding-left: 20px;
  // padding-right: 10px;
  height: 60px;
  right: ${(props) => (props.$isvisible === "true" ? "0" : "-300%")};
  width: ${(props) => props.style.width};
  background-color: #ffffff;

  border-radius: 30px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: background-color 0.3s, opacity 0.3s;
  z-index: 1;
  text-transform: uppercase;

   &:hover {
    color: #ededed;
    background-color: #00acc1;

    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

   &:after {
    content: "";
    position: absolute;
    width: 42px;
    height: 60px;
    background: #ededed;
    right: 30px;
    border-top-left-radius: 30px 30px;
    border-bottom-left-radius: 30px 30px;
  }

   ${(props) =>
    !props.$isvisible &&
    `
      opacity: 0;
      pointer-events: none;

  `}
`;

const ProfileBar = () => {

  const navigate = useNavigate();
  const { user, typeProfile } = useUserContext();
  const [namebWidth, setNameWidth] = useState("auto");
  const [isProfileBarVisible, setProfileBarVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [wasExpanded, setWasExpanded] = useState(windowWidth > 1268);
  const { localRegiao } = useDataContext();

  const typeMap = useMemo(() => {
    return {
      0: "Servidor",
      1: "RH - Regional",
      2:"Gestor de Quadro",
      3: "Especialista",
      4: "Administrador",
    };
  }, []);

  const handleIconClick = () => {
    setProfileBarVisible(!isProfileBarVisible);
  };


  const handleNotificationClick = () => {
    navigate("notificacao");
  };

  const handleResize = useCallback(() => {
    const newWidth = window.innerWidth;
    const shouldExpand = newWidth > 1268;

    if (shouldExpand !== wasExpanded) {
      setWasExpanded(shouldExpand);
    }

    if (newWidth < 768) {
      setProfileBarVisible(false);
    }

    setWindowWidth(newWidth);
  }, [wasExpanded]);

  useEffect(() => {
    let totalWidth = "";
    const upperCaseName = user.nome.toUpperCase();
    const upperCaseProfile = (typeMap[typeProfile] + " / " + user.regional).toUpperCase();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "16px Arial";

    const nameWidth = context.measureText(upperCaseName).width + 83;
    const profileWidth = context.measureText(upperCaseProfile).width + 15;

    if(nameWidth > profileWidth) {
      totalWidth = nameWidth;
    } else {
      totalWidth = profileWidth;
    }

    setNameWidth(`${totalWidth}px`);
  }, [typeMap, typeProfile, user.nome, user.regional]);

  useEffect(() => {
    if (windowWidth < 768) {
      setProfileBarVisible(false);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize, windowWidth]);

  useEffect(() => {
    if (windowWidth < 768) {
      setProfileBarVisible(false);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize, windowWidth]);

  return (
    <Container>
      <StyledIconMessage $isvisible={isProfileBarVisible} onClick={handleNotificationClick}>
        <BsEnvelope size={35} />
      </StyledIconMessage>
      <StyledProfileBar
       $isvisible={isProfileBarVisible}
       style={{ width: namebWidth }}
      >
        <span style={{ fontSize: "16px" }}>{user.nome}</span>
        <span style={{ fontSize: "12px" }}>{typeMap[typeProfile] + " / " + localRegiao() }</span>
      </StyledProfileBar>
      <StyledIcon onClick={handleIconClick}>
        <BsPerson size={35} />
      </StyledIcon>
    </Container>
  );
};

export default ProfileBar;
