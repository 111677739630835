import styled from "styled-components";

import React from "react";

export const Main = styled.main`
  margin-bottom: 0;
  padding-bottom: 2.5rem;
  background-color: #ebebeb;
`;

export const CustomHeader = styled.header`
  background-color: #00acc1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ebebeb;
  font-weight: 500;
  height: 4rem;
`;

export const LogoutPopup = styled.div`
  position: absolute;
  top: 93px;
  left: 10px;
  transform: translateX(-50%);
  width: 100px;
  height: auto;
  padding: 10px;
  background-color: #00a3ff;
  color: white;
  border-radius: 15px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  text-align: center;

  @media (max-width: 768px) {
    top: 115%;
    left: 5px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 500;
  padding-left: 30px;
  color: #ebebeb;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`;

export const ProfileDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const ProfileContainer = styled.div`
  position: relative;
  margin-right: 20px;
`;

export const Username = styled.p`
  font-size: 20px;
  color: #ebebeb;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const ProfileIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9822 17.9039C9.46997 17.9039 5.61664 18.5862 5.61664 21.3184C5.61664 24.0506 9.44553 24.7573 13.9822 24.7573C18.4944 24.7573 22.3466 24.0739 22.3466 21.3428C22.3466 18.6117 18.5189 17.9039 13.9822 17.9039Z"
      stroke="#EBEBEB"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9822 14.0069C16.9433 14.0069 19.3433 11.6058 19.3433 8.64468C19.3433 5.68357 16.9433 3.28357 13.9822 3.28357C11.0211 3.28357 8.61997 5.68357 8.61997 8.64468C8.60997 11.5958 10.9944 13.9969 13.9444 14.0069H13.9822Z"
      stroke="#EBEBEB"
      strokeWidth="1.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DivFooter = styled.div`
  background-color: #e2e5e5;
  color: #000000;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;

  p {
    margin: 0;
    padding: 0;
  }
`;
