import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  margin: 20px 0;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const TabelaContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

const Tabela = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
`;

const Cabecalho = styled.thead`
  background-color: #f4f4f4;
  font-weight: bold;

  th {
    padding: 10px;
    border-bottom: 2px solid #ddd;
    text-align: middle;
  }
`;

const Linha = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #eaeaea;
  }
`;

const Celula = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center; /* Alinha o texto horizontalmente */
  vertical-align: middle; /* Alinha o texto verticalmente */

  &:last-child {
    text-align: center;
    vertical-align: middle;
  }
`;

const BotaoVisualizar = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    background-color: #004494;
    transform: scale(0.98);
  }
`;

const ArquivosTabela = ({ label = "Anexo", arquivos }) => {
  return (
    <Container>
      <Label>{label}:</Label>
      <TabelaContainer>
        <Tabela>
          <Cabecalho>
            <tr>
              <th>Nome do Arquivo</th>
              <th>Ações</th>
            </tr>
          </Cabecalho>
          <tbody>
            {arquivos?.map(([nome, onClick], index) => (
              <Linha key={index}>
                <Celula>{nome}</Celula>
                <Celula>
                  <BotaoVisualizar onClick={onClick}>
                    Visualizar
                  </BotaoVisualizar>
                </Celula>
              </Linha>
            ))}
          </tbody>
        </Tabela>
      </TabelaContainer>
    </Container>
  );
};

export default ArquivosTabela;
