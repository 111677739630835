import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoCertifica from "Assets/certifica 1.svg";
import CustomButton from "Components/button/Button";
import { Notify, ToastType } from 'Components/notify/notify';
import { useUserContext } from "contexts/UserContext";
import "Pages/styles/perfil.css";

const Perfil = () => {
  const navigate = useNavigate();
  const { user, setTypeProfile, local, setLocal, typeProfile } = useUserContext();
  const [valorSelecionado, setValorSelecionado] = useState("");

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setValorSelecionado(value);
  };

  const typeMap = {
    0: "Servidor",
    1: "RH - Regional",
    2:"Gestor de Quadro",
    3: "Especialista",
    4: "Administrador",
  }

  useEffect(() => {
      localStorage.removeItem('localidade');
      localStorage.removeItem('tipoPerfil');
      setLocal(null);
      setTypeProfile(undefined);
  }, [])

  const handleSubmit = () => {
    if (user.tipoIds.includes(valorSelecionado)) {
      if (valorSelecionado === '1'){
        setTypeProfile(valorSelecionado);
        navigate("/selecao-regional");
      }else{
        setTypeProfile(valorSelecionado);
        navigate("/home");
      }
    }else{
      Notify({ message: `Você não possui este tipo de perfil!`, type: ToastType.Error });
    }
  };

  return (
    <div className="selectPerfil-container">
      <div className="selectPerfil-form">
        <img src={logoCertifica} alt="Imagem de seduc" />
        <form onSubmit={handleSubmit}>
          <label htmlFor="cars">Selecione seu perfil:</label>
          <select id="cargo" name="cargo" value={valorSelecionado} onChange={handleSelectChange}>
            <option disabled key={""} value={""}>Selecione...</option>
            {Object.entries(user.tipoIds).map(([index, value]) =>
            <option key={value} value={value}>{typeMap[value]}</option>)}
          </select>
          <CustomButton onClick={handleSubmit}>
            Entrar
          </CustomButton>
        </form>
      </div>
    </div>
  );
};

export default Perfil;
