import React, { useState } from 'react';
import './modalGenerico.css';
import CustomButton from 'Components/button/Button';
import Input from "Components/form/Input";
import { Notify, ToastType } from "Components/notify/notify";


const ModalInvalidar = ({ isOpen, onCancel, onConfirm }) => {
  const [motivo, setMotivo] = useState('');
  const [file, setFile] = useState('');
  const [codificado, setCodificado] = useState('');
  const [seletMotivo, setSeletMotivo] = useState(false);

  const handleMotivoChange = (event) => {
    setMotivo(event.target.value);
    setSeletMotivo(false);
  };

  const handleConfirm = async () => {
    if (!motivo | motivo ==="") {
      setSeletMotivo(true);
      Notify({
        message: `Digite o motivo de invalidar o certificado!`,
        type: ToastType.Error,
        duration:5000,
      });
     } else{
      onConfirm('NAO_VALIDAR', motivo);
     }
     //else{
    //   try {
    //     let arquivo = await codificar(file)
    //     console.log(arquivo);
    //     onConfirm('REFUTAR', motivo, arquivo);
    //   } catch (error) {
    //     console.error("Erro ao codificar o arquivo:", error);
    //     Notify({
    //       message: `O arquivo não pode ser enviado.`,
    //       type: ToastType.Error,
    //       duration:3000,
    //     });
    //   }
    // }
  };

  const handleCancele = () => {
    setMotivo('');
    setSeletMotivo(false);
    onCancel();
  }

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    if ( name === "certificado"){
      if (files[0]){
        // Verificar se o Arquivo é um pdf
        if (files[0].type !== 'application/pdf') {
          Notify({
            message: `O Anexo do recurso deve estar no formato PDF!`,
            type: ToastType.Error,
            duration:3000,
          });
          e.target.value = "";
          return;
        }
        
        // Verificar se o arquivo tem menos que 5 MB
        if (files[0].size > 5 * 1024 * 1024) {
          Notify({
            message: `O Anexo do Recurso não pode ser maior que 5 MB!`,
            type: ToastType.Error,
            duration:3000,
          });
          e.target.value = "";
          return;
        }
        setFile(files[0]);
     }
    } else if ( name === "motivo"){
      setMotivo(value);
      setSeletMotivo(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className='texto2'>
          Deseja refutar este certificado?
        </p>
        <div>
        <label>Motivo:
        { seletMotivo? (
          <p style={{ color: 'red' }}>
            Adicione um motivo para continuar!
          </p>):("")
        }
        </label>
        <textarea id="mensagem" maxLength="255" onChange={handleChange} name="motivo" rows="5" cols="30" placeholder="Digite sua mensagem aqui"></textarea>
        </div>
        {/* <br></br>
        <div>
          <Input
            type="file"
            accept=".pdf"
            name="certificado"
            label="Anexo:(Opcional)"
            onChange={handleChange}
            required={false}
          />
        </div> */}
        <CustomButton onClick={handleConfirm}>Confirmar</CustomButton>
        <CustomButton $variant="cancel" onClick={handleCancele}>Cancelar</CustomButton>
      </div>
    </div>
  );
};

export default ModalInvalidar;
