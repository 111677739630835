import React, { useState } from 'react';
import {hex} from '../../Colors';
import './card.css';

const cardVariantStyles = {
  green: {
    backgroundColor: hex.verdePrimario,
    color: hex.brancoPrimario,
    hover: {
      backgroundColor: hex.verdePrimario,
    },
  },
  blue: {
    color: hex.brancoPrimario,
    backgroundColor: hex.azulSecundario,
    hover: {
      borderColor: hex.azulSecundario,
    },
  },
  orange: {
    color: hex.brancoPrimario,
    backgroundColor: hex.laranjaPrimario,
    hover: {
      borderColor: hex.laranjaPrimario,
    },
  },
  purple: {
    color: hex.brancoPrimario,
    backgroundColor: hex.roxoPrimario,
    hover: {
      borderColor: hex.roxoPrimario,
    },
  },
  yellow: {
    backgroundColor: hex.amareloPrimario,
    color: hex.brancoPrimario,
    hover: {
      backgroundColor: hex.amareloPrimario,
    },
  },
  red: {
    backgroundColor: hex.vermelhoSecundario,
    color: hex.brancoPrimario,
    hover: {
      backgroundColor: hex.vermelhoSecundario,
    },
  },
};

const CustomCard = ({
  $variant = 'green',
  type,
  icon: IconComponent,
  children,
  style = {},
  item,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false)

  const baseStyle = cardVariantStyles[$variant] || {};
  const hoverStyle = isHover && baseStyle.hover ? baseStyle.hover : {}

  const combinedStyle = { ...baseStyle, ...hoverStyle, ...style };
  const isMultiWordTitle = item.title.split(" ").length > 1;
  const titleClass = `card-text title-p ${
    isMultiWordTitle
      ? "translate font-small title-bottom"
      : "font-large"
  } ${item.isValue ? "title-bottom" : ""}`;

  const renderCardContent = () => {
    switch (type) {
      case 'titleIcon':
        return (
          <div className="card-body content">
            <div className='content-type'>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '30px', paddingRight: '30px'}}>
                <span className={titleClass} style={{color: combinedStyle.backgroundColor}}>{item.title}</span>
                {IconComponent && <IconComponent style={{ color: combinedStyle.backgroundColor , paddingTop: '10px'}} size={130} />}
              </div>
            </div>
          </div>
        );
      case 'titleIconValue':
        return (
          <div className="card-body content">
            <div className='content-type'>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '50px', paddingLeft: '25px'}}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                  <span className="card-text total-p"  style={{color: combinedStyle.backgroundColor, marginBottom: '7px' }}>{item.value}</span>
                  <span className="card-text title-p font-large" style={{color: combinedStyle.backgroundColor}}>{item.title}</span>
                </div>
                {IconComponent && <IconComponent style={{ color: combinedStyle.backgroundColor, margin: '10px 10px 0 0' }} size={100} />}
              </div>
            </div>
          </div>
        );
      case 'titleValue':
        return (
          <div className="card-body content">
            <div className='content-type'>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '50px', paddingLeft: '25px'}}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                  <span className="card-text total-p"  style={{color: combinedStyle.backgroundColor}}>{item.value}</span>
                  <span className="card-text title-p font-large" style={{color: combinedStyle.backgroundColor}}>{item.title}</span>
                </div>
                {IconComponent && <IconComponent style={{ color: combinedStyle.backgroundColor, margin: '10px 10px 0 0' }} size={110} />}
              </div>
            </div>
        </div>
        );
      case 'description':
        return (
          <div className="card-body content">
            <div className='content-type'>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '25px' }}>
                <span className={titleClass} style={{color: combinedStyle.backgroundColor}}>{item.title}</span>
                {IconComponent && <IconComponent style={{ color: combinedStyle.backgroundColor, margin: '10px 10px 0 0' }} size={110} />}
              </div>
              <div>
                <p className="card-text description-p">{item.description}</p>
                <p className="card-text contrato-p">{item.contrato}</p>
                <p className="card-text cargo-p">{item.cargo}</p>
              </div>
            </div>
          </div>
        );
      case 'titleValueSlice':
          return (
            <div className="card-body content" style={{height:'50px'}}>
              <div className='content-type'>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '50px', paddingLeft: '25px', paddingRight:'25px'}}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                    <span className="card-text title-p font-large" style={{color: combinedStyle.backgroundColor, paddingBottom: '20px'}}>{item.title}</span>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingLeft:'5px'}}>
                    <span className="card-text total-p"  style={{color: combinedStyle.backgroundColor}}>{item.value}</span>
                  </div>
                </div>
              </div>
            </div>
          );
      default:
        return (
          <div className="card-body content">
            <div className='content-type'>
              <div style={{paddingTop: '50px', paddingLeft: '25px'}}>
                <span className={titleClass} style={{color: combinedStyle.backgroundColor}}>{item.title}</span>
              </div>
            </div>
          </div>
        );
    }
  };

  const renderCardButton = () => {
    switch (type){
      case 'titleValueSlice':
        return (
        <div
          className="card-body button-menu"
          style={combinedStyle}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={item.buttonAction}
        >
          Acessar
        </div>
        );
      default:
        return (
        <div
          className="card-body button-menu"
          style={combinedStyle}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={item.buttonAction}
        >
          Acessar
        </div>
        );
    }
  };

  return (
    <div
      className={"menu-items"}
      {...props}
    >
      {renderCardContent()}
      {renderCardButton()}
    </div>
  );
};

export default CustomCard;
