import React from "react";
import CardGroup from "Components/card/CardGroup";
import { GoVerified } from "react-icons/go";
import { MdOutlinePendingActions, MdOutlineBlock, MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AiOutlineException } from "react-icons/ai";
import { BsClipboard2Check } from "react-icons/bs";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { GrDocumentDownload } from "react-icons/gr";
import {MdOutlineDataset} from "react-icons/md";
import { useDataContext } from "contexts/DataContext";


const HomeRh = () => {
  const navigate = useNavigate();
  const { certificadosCountRegional } = useDataContext();
  let itens = [];

  const handleClick = (path) => {
    navigate(path);
  };


  let pendente = 0;
  let verificado =  0;
  let deferido =  0;
  let naoValidados =  0;
  let recurso =  0;
  let refutado =  0;
  if (certificadosCountRegional){
    for (let index = 0; index < certificadosCountRegional.length; index++) {      
      pendente = certificadosCountRegional[index]['certificadosPendentes'];
      verificado = certificadosCountRegional[index]['certificadosConferidos'];
      naoValidados = certificadosCountRegional[index]['certificadosNaoValidos'];
      refutado = certificadosCountRegional[index]['certificadosRefutados'];
      deferido = certificadosCountRegional[index][''] ? certificadosCountRegional[index][''] : 0;
    }
  }


  itens = [
    {
      id: 0,
      title: "Pendentes",
      $variant: "yellow",
      type: "titleIconValue",
      value: pendente,
      icon: MdOutlinePendingActions,
      buttonAction: () => {
        handleClick("/pendentes");
      },
    },
    {
      id: 1,
      title: "Conferidos",
      $variant: "orange",
      type: "titleIconValue",
      value: verificado,
      icon: BsClipboard2Check,
      buttonAction: () => {
        handleClick("/conferidos");
      },
    },
    // {
    //   id: 2,
    //   title: "Deferidos",
    //   $variant: "green",
    //   type: "titleIconValue",
    //   value: deferido,
    //   icon: GoVerified,
    //   buttonAction: () => {
    //     handleClick("/deferidos");
    //   },
    // },
    {
      id: 3,
      title: "Não Validados",
      $variant: "red",
      type: "titleIconValue",
      value: naoValidados,
      icon: MdOutlineBlock,
      buttonAction: () => {
        handleClick("/naoValidados");
      },
    },
    {
      id: 8,
      title: "Refutados",
      $variant: "purple",
      type: "titleIconValue",
      value: refutado,
      icon: MdOutlineCancel,
      buttonAction: () => {
        handleClick("/refutados");
      },
    },
    // {
    //   id: 4,
    //   title: "Recursos",
    //   $variant: "purple",
    //   type: "titleIconValue",
    //   value: recurso,
    //   icon: AiOutlineException,
    //   buttonAction: () => {
    //     handleClick("/recursos");
    //   },
    // },
    {
      id: 6,
      title: "Relatórios",
      $variant: "blue",
      type: "titleIcon",
      icon: GrDocumentDownload,
      buttonAction: () => {
        handleClick("/relatorios");
      },
    },
    {
      id: 5,
      title: "Dashboard",
      $variant: "blue",
      type: "titleIcon",
      icon: TbLayoutDashboardFilled,
      buttonAction: () => {
        handleClick("/certificados");
      },
    },
    {
      id: 7,
      title: "Normativa",
      $variant: "blue",
      type: "titleIcon",
      icon: MdOutlineDataset,
      buttonAction: () => {
        handleClick("/relatorios");
      },
    }
  ];
  return (
    <>
      <CardGroup menuItems={itens} />
    </>
  );
};

export default HomeRh;
