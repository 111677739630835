import React, { useState } from 'react';
import './modalGenerico.css';
import CustomButton from 'Components/button/Button';
import { Notify, ToastType } from "Components/notify/notify";
import { codificar } from 'utils/Encode';

const ModalRefutar = ({ isOpen, onCancel, onConfirm }) => {
  const [motivo, setMotivo] = useState('');
  const [file, setFile] = useState();
  const [seletMotivo, setSeletMotivo] = useState(false);

  const handleConfirm = async () => {
    if (!motivo | motivo ==="") {
      setSeletMotivo(true);
      Notify({
        message: `Digite o motivo para poder refutar o certificado!`,
        type: ToastType.Error,
        duration:5000,
      });
    }else{
      try {
        if (file !== undefined){
        let arquivo = await codificar(file)
        console.log(arquivo);
        onConfirm('REFUTAR', motivo, arquivo);
       } else {
        onConfirm('REFUTAR', motivo);
       }
      } catch (error) {
        console.error("Erro ao codificar o arquivo:", error);
        Notify({
          message: `O arquivo não pode ser enviado.`,
          type: ToastType.Error,
          duration:3000,
        });
      }
    }
  };

  const handleCancele = () => {
    setMotivo('');
    setSeletMotivo(false);
    onCancel();
  }

  const handleChange = async (e) => {
    const { name, value, files } = e.target;
    if ( name === "motivo"){
      setMotivo(value);
      setSeletMotivo(false);
    }
    if ( name === "certificado"){
      if (files['length'] === 0){
        setFile()
      } else if (files['length'] === 1){
        setFile(files[0])
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className='texto2'>
          Deseja refutar este certificado?
        </p>
        <div>
        <label>Motivo:
        { seletMotivo? (
          <p style={{ color: 'red' }}>
            Adicione um motivo para continuar!
          </p>):("")
        }
        </label>
        <textarea id="mensagem" maxLength="255" onChange={handleChange} name="motivo" rows="5" cols="30" placeholder="Digite sua mensagem aqui"></textarea>
        </div>
        <br></br>
        <CustomButton onClick={handleConfirm}>Confirmar</CustomButton>
        <CustomButton $variant="cancel" onClick={handleCancele}>Cancelar</CustomButton>
      </div>
    </div>
  );
};

export default ModalRefutar;
