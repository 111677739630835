import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import "./SelecaoRegional.css";
import CardGroup from "Components/card/CardGroup";
import { useDataContext } from "contexts/DataContext";

const SelecaoRegional = () => {
     const navigate = useNavigate();
     const { user, local, setLocal, typeProfile } = useUserContext();
     const {certificadosCountRegionalAll, regionais} = useDataContext();
     const handleClick = (regional) => {
          setLocal(regional);
          navigate('/home');
     };

     const cores = ["green", "blue", "orange", "purple", "yellow", "red"]

     let lastSelectedIndex = null; // Variável para armazenar o índice anterior

     function itemAleatorio(array) {
     let newIndex;

     do {
          newIndex = Math.floor(Math.random() * array.length);
     } while (newIndex === lastSelectedIndex);

     lastSelectedIndex = newIndex;

     return array[newIndex];
     }
      
     let regionais_list = []

     let minhaRegional = []

     const retornoValore = (dados) => {
          switch (typeProfile) {
               case 1:
                    return dados['certificadosPendentes']
               case 2:
                    return dados['certificadosDeferidos']
               case 3:
                    return dados['certificadosConferidos']
               default: return 0
          }
     }
     
     regionais.forEach( regiao => {
          if (regiao.id === Number(user.nregional)){
               let dados = [];
               if (certificadosCountRegionalAll){
                    dados = certificadosCountRegionalAll.find((item) => item.regional.id === regiao.id);
               }
               minhaRegional.push(
                    {
                         id: regiao.id,
                         title: regiao.name,
                         $variant: itemAleatorio(cores),
                         type: "titleValueSlice",
                         value: retornoValore(dados),
                         buttonAction: () => {
                         handleClick(regiao.id);
                         }
                    }
               )
          } else {
               let dados = [];
               if (certificadosCountRegionalAll){
                    dados = certificadosCountRegionalAll.find((item) => item.regional.id === regiao.id);
               }
               regionais_list.push(
                    {
                         id: regiao.id,
                         title: regiao.name,
                         $variant: itemAleatorio(cores),
                         type: "titleValueSlice",
                         value: retornoValore(dados),
                         buttonAction: () => {
                         handleClick(regiao.id);
                         }
                    }
               )
          }
     })

     useEffect(() =>{
          localStorage.removeItem('localidade', local);
     })
    
     return (
          <>
          <div className="container">
               <div>
                    <h1>Minha Regional</h1>
                    <CardGroup menuItems={minhaRegional} />
               </div>
               <br></br>
               <div>
                    <h1>Outras Regionais</h1>
                    <CardGroup menuItems={regionais_list} />
               </div>
          </div>
          </>
     );
}

export default SelecaoRegional;