import React ,{ useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import { useDataContext } from "contexts/DataContext";
import Container from "Components/container/Container";
import ArquivosTabela from "Components/informationsField/ArquivosTabela";
import ModalReprovar from "Components/modal/ModalReprovar";
import ModalGenerico from "Components/modal/ModalGenerico";
import TextoField from "Components/informationsField/TextoField";
import StatusField from "Components/informationsField/StatusField";
import CustomButtonCancelar from "Components/button/CustomButtonCancelar";
import CustomButton from "Components/button/Button";
import './recursostyle.css'

const RecursosDetalhes = () => {
  const { typeProfile} = useUserContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const{ mutationPutAcaoRecurso , setRecursoId, recursoBytes} = useDataContext();
  const [isModalOpenReprovar, setModalOpenReprovar] = useState(false);
  const location = useLocation();
  const data = location.state?.data;
  let temResposta = false;
  let temAnexo = false;

  useEffect(() => {
    setRecursoId(data.id);
    console.log("RecursoBytes", recursoBytes)
    
  }, [recursoBytes]);

  if (data.resposta !== null) {
    temResposta = true;
  }

  if(recursoBytes?.justificativa !== "Não contém"){
    temAnexo = true;
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  };
 
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalReprovar = () => {
    setModalOpenReprovar(true);
  };

  const handleCloseModalReprovar = () => {
    setModalOpenReprovar(false);
  };

  const downloadPDF = (certificado) => {
    const binaryData = atob(certificado);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    // Abre o PDF em uma nova guia
    window.open(url, '_blank');
  };

  const aprovar = async () => {
    const acaoAprovar = {
      roleEnum: typeProfile,
      acao : "APROVAR",
      certificado: data.certificadoId, 
    }

    await mutationPutAcaoRecurso.mutateAsync({data:acaoAprovar, path:"recursos"});
    return;
  };

  const reprovar = async (motivo, anexomotivo) => {
    const acaoReprovar = {
      roleEnum: typeProfile,
      acao : "REPROVAR",
      certificado: data.certificadoId,
      resposta: motivo,
      anexoResposta: anexomotivo,
    }

    await mutationPutAcaoRecurso.mutateAsync({data:acaoReprovar, path:"recursos"});
    return;
  };

  return (
    <Container titulo="Dados do Recurso">
      {temAnexo ? (
        <>
        <ArquivosTabela 
          label="Anexo" 
          arquivos={[
            ["JustificativaAnexo.pdf", () => downloadPDF(recursoBytes.justificativa)],
          ]} 
        />
        <TextoField label="Justificativa" value={data.justificativa} />
      </>
      ) : (
        <TextoField label="Observação" value="Sem acesso aos anexos!" />
      )
      }
      {temResposta && (
        <>
          <ArquivosTabela 
            label="Anexo" 
            arquivos={[
              ["RespostaAnexo.pdf", () => downloadPDF(recursoBytes.resposta)],
            ]} 
          />
          <TextoField label="Resposta" value={data.resposta} />
        </>
      )}
      <StatusField status={data.status} upperCase={true} centered={true} />
      <div
        style={{
          display: "flex",
          gap: "30px",
          width: "100%",
          justifyContent: "center",
        }}
      >
      <ModalGenerico
        isOpen={isModalOpen}
        text='Deseja aprovar as informações contidas neste recurso? Após aprovar, não será mais possível voltar. Se todas as informações estiverem corretas, clique em "Confirmar". Caso contrário, clique em "Cancelar".'
        onCancel={handleCloseModal}
        textoOk="Aprovar"
        textoCancelar="Cancelar"
        onProceed={aprovar}
      />

      <ModalReprovar
      isOpen={isModalOpenReprovar}
      onCancel={handleCloseModalReprovar}
      onConfirm={reprovar}
      text='Deseja reprovar as informações contidas neste recurso? se tiver certeza clique em "Confirmar". Caso contrário, clique em "Cancelar".' 
      />
        <div
          style={{
            display: "flex",
            gap: "50px",
            justifyContent: "space-between",
            width: "80%",
            marginTop: "20px",
          }}
        >       
        {(typeProfile === 2 || typeProfile === 3) && data.status === "PENDENTE" && (
          <>
            <CustomButton onClick={handleOpenModal}>Aprovar</CustomButton>
            <CustomButton onClick={handleOpenModalReprovar}>Reprovar</CustomButton>
          </>
        )}
        <CustomButtonCancelar/>
        </div>  
      </div>
    </Container>


  );
};

export default RecursosDetalhes;
