import { hex } from "Colors";
import React, { useState, useMemo } from "react";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "contexts/UserContext";



const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  min-width: 400px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
`;

const TableHead = styled.thead`
  background-color: #00acc1;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
`;

const TableBody = styled.tbody`
  border-bottom: 2px solid #00acc1;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #dddddd;
  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  &:nth-of-type(odd) {
    background-color: #ebebeb;
  }
  &:last-of-type {
    border-bottom: 2px solid #00acc1;
  }
`;

const TableCell = styled.td`
  padding: 12px 15px;
  text-align: center;
`;

const TableHeaderCell = styled.th`
  background-color: #04a3fb;
  padding: 12px 15px;
  text-align: center;
`;

const Input = styled.input`
  width: 200px;
  height: 30px;
  border: 3px solid #04a3fb;
  border-radius: 15px;
  font-size: 16px;
  text-align: justify;
  display: flex;
  justify-content: space-between;
  align-items: end;
  background-color: white;
  padding-left: 10px;

  &:focus {
    outline: 4px solid #79c1e0;
  }
`;

const ActionHeaderTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Paginacao = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    background-color: #00acc1;
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
  }

  button:disabled {
    color: black;
    background-color: #b1b0b0;
    cursor: not-allowed;
  }

  span {
    margin: 0 10px;
    font-size: 16px;
  }
`;

const extractText = (element) => {
  if (React.isValidElement(element)) {
    return extractText(element.props.children);
  } else if (Array.isArray(element)) {
    return element.map(extractText).join(" ");
  }
  return element ? element.toString() : "";
};

const Tabela = ({
  data,
  header,
  paginated = false,
  search = [],
  hasAdd = false,
}) => {
  const { typeProfile } = useUserContext();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig] = useState(null);
  const itemsPerPage = 10;
  const hasSearch = search.length !== 0;

  let isServidor = typeProfile === 0;

  const sorted = useMemo(() => {
    if (!sortConfig) return data;
    const { key, direction } = sortConfig;
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    if (!hasSearch || !searchTerm) return sorted;
    const searchText = searchTerm.toLowerCase();
    return sorted.filter((item) =>
      search.some((field) =>
        extractText(item[field]).toLowerCase().includes(searchText)
      )
    );
  }, [sorted, searchTerm, search, hasSearch]);

  const displayData = useMemo(() => {
    if (!paginated) return filteredData;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [currentPage, itemsPerPage, filteredData, paginated]);

  const clickNovoCertifica = () => {
    navigate("cadastrar");
  };
  
  if (!data || data.length === 0) {
    
    return (
    
    <>
      {isServidor && (<MdAddCircle
      size={50}
      color={hex.azulSecundario}
      onClick={clickNovoCertifica}
      style={{ cursor: "pointer" }}
      />)}
    <p>Nenhum dado disponível.</p>
    </>);
  }

  

  const colunas = Object.keys(data[0]);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <>
      <ActionHeaderTable>
        {hasSearch && (
          <Input
            type="text"
            placeholder="Pesquisar..."
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        )}
        {hasAdd && (
          <MdAddCircle
            size={50}
            color={hex.azulSecundario}
            onClick={clickNovoCertifica}
            style={{ cursor: "pointer" }}
          />
        )}
      </ActionHeaderTable>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((coluna, index) => (
              <TableHeaderCell key={index}>{coluna}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayData.map((dado, index) => (
            <TableRow key={index}>
              {colunas.map((coluna, colIndex) => (
                <TableCell key={colIndex}>{dado[coluna]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {paginated && (
        <Paginacao>
          <button
            onClick={() =>
              setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
            }
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span>{`Página ${currentPage} de ${totalPages}`}</span>
          <button
            onClick={() =>
              setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Próxima
          </button>
        </Paginacao>
      )}
    </>
  );
};

export default Tabela;
