import React, { useState } from 'react';
import './modalGenerico.css';
import CustomButton from 'Components/button/Button';
import { Notify, ToastType } from "Components/notify/notify";

import motivoIndeferimento from "fakeData/motivosIndeferimento.json"
import ModalSelecionarMotivos from "./ModalSelecionarMotivos"; // Nova modal de seleção

const ModalAvaliacao = ({ isOpen, onCancel, onConfirm, status }) => {
  const [avaliacao, setAvaliacao] = useState("");
  const [deferido, setDeferido] = useState();
  const [motivo, setMotivo] = useState('');
  const [seletMotivo, setSeletMotivo] = useState(false);
  const [seletTipoErro, setSeletTipoError] = useState(false);
  const [motivosSelecionados, setMotivosSelecionados] = useState([]); // Array de motivos selecionados
  const [isMotivoModalOpen, setIsMotivoModalOpen] = useState(false); // Estado para abrir a modal

  const handleDeferir = () => {
    setAvaliacao("Deferir");
    setDeferido(true);
    setSeletTipoError(false);
    setMotivo("");
  };

  const handleIndeferir = () => {
    setAvaliacao("Indeferir");
    setDeferido(false);
    setSeletTipoError(false);
  };

  const handleOpenMotivoModal = () => {
    setIsMotivoModalOpen(true); // Abre a modal de motivos
  };

  const handleCloseMotivoModal = () => {
    setIsMotivoModalOpen(false); // Fecha a modal de motivos
  };

  // const handleMotivoChange = (event) => {
  //   setMotivo(event.target.value);
  // };

  const handleConfirm = () => {
    if (deferido !== undefined){
      if (deferido) {
        if (status !== "INDEFERIDO"){
          onConfirm('DEFERIR', motivosSelecionados.join(', '));
        }
      } else if (!deferido){
        if (motivosSelecionados.length === 0) {
          setSeletMotivo(true);
          Notify({
            message: `Selecione pelo menos um motivo para refutar o certificado!`,
            type: ToastType.Error,
            duration: 5000,
          });
        } else {
          onConfirm('INDEFERIR', motivosSelecionados.join(', ')); // Enviar os motivos selecionados
        }
      }
    }else{
      setSeletTipoError(true);
          Notify({
            message: `Selecione um Status para a avaliação do certificado!`,
            type: ToastType.Error,
            duration:5000,
          });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className='texto2'>
          Deseja confirmar a avaliação deste certificado? Após a confirmação, não será mais possível editar. Somente o especialista poderá modificar a avaliação.
        </p>
        { seletTipoErro? (
          <p style={{ color: 'red' }}>
            Selecione o tipo de avaliação!
          </p>):("")
        }
        <div className="radio-buttons">
          <label>
            <p className='opcao'>Deferir:</p>
            <input type="radio" name="avaliacao" value="Deferir" onChange={handleDeferir} />
          </label>
          <label>
            <p className='opcao'>Indeferir:</p>
            <input type="radio" name="avaliacao" value="Indeferir" onChange={handleIndeferir}/>
          </label>
        </div>
        {avaliacao === "Indeferir" && (
          <div className="motivo-select">
            <label className="opcao">Motivo:</label>
            {seletMotivo && (
              <p style={{ color: "red" }}>Adicione um motivo para continuar!</p>
            )}
            <button onClick={handleOpenMotivoModal} className="botao-selecionar">
              Selecionar motivo
            </button>
            {motivosSelecionados.length > 0 && (
              <p>{motivosSelecionados.length} motivo(s) selecionado(s)</p>
            )}
          </div>
        )}
        <CustomButton onClick={handleConfirm}>Confirmar</CustomButton>
        <CustomButton $variant="cancel" onClick={onCancel}>Cancelar</CustomButton>

        {/* Modal de seleção de motivos */}
        {isMotivoModalOpen && (
          <ModalSelecionarMotivos
            motivos={motivoIndeferimento}
            motivosSelecionados={motivosSelecionados}
            onClose={handleCloseMotivoModal}
            onSave={(selectedMotivos) => {
              setMotivosSelecionados(selectedMotivos);
              handleCloseMotivoModal();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ModalAvaliacao;
