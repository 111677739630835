import React, { useState } from "react";
import "./ModalSelecionarMotivos.css";

const ModalSelecionarMotivos = ({ motivos, motivosSelecionados, onClose, onSave }) => {
  const [filtro, setFiltro] = useState(""); // Estado para a pesquisa
  const [selecionados, setSelecionados] = useState(motivosSelecionados || []);

  const handleToggleMotivo = (motivo) => {
    if (selecionados.includes(motivo)) {
      setSelecionados(selecionados.filter((m) => m !== motivo));
    } else {
      setSelecionados([...selecionados, motivo]);
    }
  };

  const handleSave = () => {
    onSave(selecionados); // Retorna os motivos selecionados ao componente pai
  };

  const motivosFiltrados = motivos.filter((motivo) =>
    motivo.descricao.toLowerCase().includes(filtro.toLowerCase())
  );

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Selecionar Motivos</h3>
        <input
          type="text"
          placeholder="Pesquisar..."
          value={filtro}
          onChange={(e) => setFiltro(e.target.value)}
        />
        <ul className="lista-motivos">
          {motivosFiltrados.map((motivo, index) => (
            <li key={index}  onClick={() => handleToggleMotivo(motivo.descricao)}>
              <input
                type="checkbox"
                checked={selecionados.includes(motivo.descricao)}
                onChange={() => handleToggleMotivo(motivo.descricao)}
              />
              {motivo.descricao}
            </li>
          ))}
        </ul>
        <button onClick={handleSave}>Salvar</button>
        <button onClick={onClose}>Cancelar</button>
      </div>
    </div>
  );
};

export default ModalSelecionarMotivos;
