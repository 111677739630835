import React from "react";
import { Outlet } from "react-router-dom";
import { UserProvider } from "contexts/UserContext";

function LayoutContext() {
  return (
    <>
      <UserProvider>
        <Outlet />
      </UserProvider>
    </>
  );
}

export default LayoutContext;
