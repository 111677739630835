import Tooltip from 'Components/tooltip/Tooltip';
import React from 'react'
import { GoQuestion } from 'react-icons/go';
import styled from 'styled-components';

const CustomSelect = styled.select`
  font-size: 1em;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ebebeb;
  height: 35px;
  margin: 0.2em 0 1em 0;
`;

const Option = styled.option`
  color: black;
`;

const LabelWithTooltip = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em; /* Ajuste de espaçamento, se necessário */
`;

const Select = ({ name, label, value, onChange, options, tooltip=false, required=false, disabled=false }) => (
  <>
    <LabelWithTooltip>
      <label htmlFor={name} style={{ marginRight: '0.5em' }}>{label}</label>
      {tooltip && (
        <Tooltip text={tooltip}>
          <GoQuestion size={23} />
        </Tooltip>
      )}
    </LabelWithTooltip>
    <CustomSelect
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      required={required ? true : false}
      disabled={disabled ? true : false}
      >
      <Option value="" disabled>
        Selecione
      </Option>
      {options.length > 0 ? (
        options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))
      ) : (
        <Option value="" disabled>
          Nenhuma opção disponível
        </Option>
      )}
    </CustomSelect>
  </>
);

export default Select;
