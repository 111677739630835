import { useUserContext } from "contexts/UserContext";
import HomeServidor from "./HomeServidor";
import HomeRh from "./HomeRh";
import HomeEspecialista from "./HomeEspecialista";
import HomeGestor from "./HomeGestor";

const Home = () => {
  const { typeProfile } = useUserContext();

  const renderHome = () => {
    switch (typeProfile) {
        case 0:
        return (
          <HomeServidor />
        )
        case 1:
          return (
            <HomeRh />
          )
        case 2:
          return (
            <HomeGestor />
          )
        case 3:
          return (
            <HomeEspecialista />
          )
        case 4:
        return (
          <HomeServidor />
        )
      default:
        return (
          <HomeServidor />
        )
    }
  }

  return (
    renderHome()
  );
};

export default Home;
