import React from "react";
import styled from "styled-components";
import Container from 'Components/container/Container'

export const Title = styled.summary`


  text-align: left;
  font-size: 20px;
  cursor: pointer;
`;

export const Line = styled.hr`
margin-top: 10px;
margin-bottom: 10px;
`;

export const Paragraph = styled.p`
text-align: left;
  font-size: 14px;
`;

const Suporte = () => {
  return (
    <Container titulo="Suporte">
      <details>
        <Title>Onde posso tirar minhas dúvidas ou reportar algum problema?
        </Title>
        <br/>
        <Paragraph>
        Em caso de dúvidas ou problemas durante a utilização do sistema, você
        pode acionar o suporte por e-mail através do endereço{" "}
        <strong>suportegs@seduc.to.gov.br</strong> com as seguintes informações:
      </Paragraph>

      <Paragraph>
        <strong>1.</strong> Informe no assunto do e-mail o sistema em questão
        (por exemplo, o assunto seria: "Certifica - problema x");
      </Paragraph>

      <Paragraph>
        <strong>2.</strong> Descreva detalhadamente o erro, incluindo o passo a
        passo para reproduzir o problema, se possível;
      </Paragraph>

      <Paragraph>
        <strong>3.</strong> Anexe uma captura de tela mostrando o erro.
      </Paragraph>

      </details>
      <Line/>
      <details>
        <Title>Minhas especializações contam para a progressão de carreira?
        </Title>
        <br/>
        <Paragraph>
        Mesmo que seja possível cadastrá-las, as especializações <strong>não</strong> contam para o processo de progressão de carreira. As duas únicas graduações
        que contam neste processo são a de <strong>mestrado</strong> e <strong>doutorado</strong>.
        </Paragraph>

      </details>
      <Line/>
      <details>
        <Title>Por onde posso acompanhar o estado atual do meu certificado/recurso?
        </Title>
        <br/>
        <Paragraph>
          Ao selecionar o ícone de lupa na coluna <strong>ação</strong> da lista onde se encontra o seu certificado/recurso é possível visualizar os seus <strong>detalhes</strong>. Ao
          final das informações apresentadas, encontra-se uma tag colorida, com o estado atual do documento e uma <strong>pequena seta ao lado</strong>, que caso selecionada expande
          as informações de "Fase", "Data", "Responsável" e "Status" do documento em questão.
        </Paragraph>

      </details>
      <Line/>
      <details>
        <Title>Fiz um segundo mestrado, posso registrar no sistema?
        </Title>
        <br/>
        <Paragraph>
          Sim! não há problema em adicionar todas as suas graduações e especializações, mas após o primeiro mestrado já estar ativo os próximos <strong>não contam</strong> para o processo de progressão de carreira.
        </Paragraph>
      </details>
      <Line/>
      <details>
        <Title>Meu certificado foi indeferido! o que posso fazer?
        </Title>
        <br/>
        <Paragraph>
          Caso seu certificado tenha sido indeferido, não se desespere! é possível acionar um <strong>recurso</strong> contendo as devidas informações e documentos que serão analisados
          e possivelmente poderão dar uma <strong>segunda chance</strong> ao seu certificado.
        </Paragraph>
      </details>

    </Container>
  );
};

export default Suporte;
