import React, { useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import styled from "styled-components";
import ModalGenerico from "Components/modal/ModalGenerico";
import ModalAvaliacao from "Components/modal/ModalAvaliar";
import ModalRefutar from "Components/modal/ModalRefutar";
import ModalInvalidar from "Components/modal/ModalInvalidar";
import ModalAtivarCertificado from "Components/modal/ModalAtivarCertificado";
import Container from "Components/container/Container";
import CustomButton from "Components/button/Button";
import Field from "Components/informationsField/Field";
import TextoField from "Components/informationsField/TextoField";
import ArquivosTabela from "Components/informationsField/ArquivosTabela";
import { useDataContext } from "contexts/DataContext";

const Informacoes = styled.div`
  font-size: 18px;
  margin-left: 100px;
  margin-right: 100px;
`;

const GridData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
`;

const CertificadoDados = () => {

  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenAvaliar, setModalOpenAvaliar] = useState(false);
  const [isModalOpenRefutar, setModalOpenRefutar] = useState(false);
  const [isModalOpenInvalidar, setModalOpenInvalidar] = useState(false);
  const [isModalAtivarCertificado, setModalAtivarCertificado] = useState(false);
  const [vinculo, setVinculo] = useState('');
  const location = useLocation();
  const { typeProfile, user} = useUserContext();
  const { mutationPutAcaoCertificado, setPessoaVinculoId, pessoaVinculo, docCertificados, setCertificadoData, mutationPutAtivarCertificado } = useDataContext();
  const data = location.state?.data;
  const [users, setUsers] = useState([]);
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
  const [isLoading, setIsLoading] = useState(true);
  const page = location.pathname.substring(0, location.pathname.lastIndexOf("/"))



  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalAvaliar = () => {
    setModalOpenAvaliar(true);
  };

  const handleCloseModalAvaliar = () => {
    setModalOpenAvaliar(false);
  };

  const handleOpenModalRefutar = () => {
    setModalOpenRefutar(true);
  };

  const handleCloseModalRefutar = () => {
    setModalOpenRefutar(false);
  };

  const handleOpenModalInvalidar = () => {
    setModalOpenInvalidar(true);
  };

  const handleCloseModalInvalidar = () => {
    setModalOpenInvalidar(false);
  };

  const handleOpenModalAtivarCertificado = () => {
    setModalAtivarCertificado(true);
  };

  const handleCloseModalAtivarCertificado = () => {
    setModalAtivarCertificado(false);
  };

  const handleEditClick = () => {
    setUsers(pessoaVinculo)
    if (!pessoaVinculo || !data) {
      navigate(-1);
    } else {
      navigate(page + "/editar", { state: { data, users } });
    }
  };

  useEffect(() => {
    const fetchVinculo = async () => {
      await setPessoaVinculoId(data.vinculoId)
      await setCertificadoData(data);
    };
    fetchVinculo();
    setIsLoading(false);
  }, [data.vinculoId]);

  useEffect(() => {
    if (pessoaVinculo) {
      const vinculoNumero = pessoaVinculo.vinculo.id;
      const vinculoNome = pessoaVinculo.vinculo.categoria;
      setVinculo(vinculoNumero + " - " + vinculoNome);
    }
  }, [pessoaVinculo])

  const backPage = () => {
    navigate(-1);
  };

  const conferir = async () => {
    data.status = 1;
    data.dataConferencia = formattedDate;
    data.quemConferiu = user.nome;

    const conferencia = {
      acao: "CONFERIR",
      motivo: "",
      anexoMotivo: "",
      roleEnum: typeProfile,
    };
    const path = "/conferidos";
    try {
      await mutationPutAcaoCertificado.mutateAsync({ data: data, conferencia: conferencia });
      navigate(path);
    } catch (error) {
      console.error("Error submitting form:", error);
      navigate(path);
    }
  };

  const avaliar = async (resultado, motivo, anexoMotivo) => {
    data.acao = resultado === "" ? "" : resultado;
    data.motivo = motivo;

    const avaliacao = {
      acao: data.acao,
      motivo: data.motivo,
      anexoMotivo: anexoMotivo,
      roleEnum: typeProfile,
    };

    try {
      let path = ""
      if (resultado === 'DEFERIR') {
        path = "/deferidos";
      } else
        if (resultado === 'INDEFERIR') {
          path = "/indeferidos";
        } else
          if (resultado === 'PENDENTE') {
            path = "/pendentes";
          } else
            if (resultado === 'REFUTAR') {
              path = "/home";
            } else
              if (resultado === 'CONFERIR') {
                path = "/conferidos";
              } else {
                path = "/home";
              }
      await mutationPutAcaoCertificado.mutateAsync({ data: data, conferencia: avaliacao});
      handleCloseModalAvaliar();
      navigate(path);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    handleCloseModalAvaliar();
  };
  
  const refutar = async (resultado, motivo, anexoMotivo) => {
    data.acao = resultado === "" ? "" : resultado;
    data.motivo = motivo;
    const avaliacao = {
      acao: data.acao,
      motivo: data.motivo,
      anexoMotivo: anexoMotivo,
      roleEnum: typeProfile,
    };
    try {
      let path = ""
      if (resultado === 'REFUTAR') {
        path = "/home";
      }
      await mutationPutAcaoCertificado.mutateAsync({ data: data, conferencia: avaliacao });
      handleCloseModalRefutar();
      navigate(path);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    handleCloseModalRefutar();
  };

  const ativar = async (diario, portaria) => {
    const dados = {
      id: data.id,
      numeroDiario: diario,
      numeroPortaria: portaria,

    }
    try {
      await mutationPutAtivarCertificado.mutateAsync({data: dados});
      handleCloseModalAtivarCertificado();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  const downloadPDF = (certificado) => {
    const binaryData = atob(certificado);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    window.open(url, '_blank');
  };
  if (!isLoading) {
    return (
      <Container titulo={"Dados do Certificado"}>
        <Informacoes>
          <Field label="Proprietário do certificado" value={pessoaVinculo ? pessoaVinculo.nome : ''} />
          <Field label="Vínculo" value={vinculo || ''} />
          <Field label="Nome do curso" value={data.nome} />
          <Field label="Instituição" value={data.instituicao} />
          <Field label="Tipo de qualificação" value={data.tipoQualificacao} />
          <Field label="Carga Horária" value={data.cargaHoraria + " Horas"} />
          <GridData>
            <Field label="Data de início" value={data.dataInicio} />
            <Field label="Data de término" value={data.dataFim} />
            <Field label="Data de cadastro" value={data.dataCadastro} />
            <Field label="Data de conferência" value={data.dataConferencia} />
          </GridData>
          <ArquivosTabela 
          label="Anexo" 
          arquivos={[
            ["JustificativaAnexo.pdf", () => downloadPDF(docCertificados)],
          ]} 
          />
          {data.validacaoOnline ? (<>
            <Field label="Validação Online" value={
              <>
                <a href={data.linkValidacao} target="_blank" rel="noopener noreferrer">
                  Link de Validação
                </a>
              </>
            } />
          </>) : ("")}
          <TextoField label="Observação" value={data.observacao} />
          {data.respostaRh && (
            <TextoField label="Justificativa Refutação" value={data.respostaRh} />
          )
          }
        </Informacoes>
        <div
          style={{
            display: "flex",
            gap: "30px",
            justifyContent: "space-between",
            width: "80%",
            marginLeft: "10%",
          }}
        >
          {data.status === "PENDENTE" && <CustomButton onClick={handleEditClick}>Editar</CustomButton>}
          {typeProfile === 1 && data.status === "REFUTADO" && (<CustomButton onClick={handleEditClick}>Editar</CustomButton>)}
          {typeProfile === 1 && data.status === "PENDENTE" && <CustomButton onClick={handleOpenModal}>Conferir</CustomButton>}
          {typeProfile === 1 && data.status === "NAO_VALIDADO" && <CustomButton onClick={handleOpenModal}>Conferir</CustomButton>}
          {typeProfile === 1 && data.status === "PENDENTE" && data.validacaoOnline && <CustomButton $variant="warning" onClick={handleOpenModalInvalidar}>Invalidar</CustomButton>}
          {typeProfile === 1 && data.status === "PENDENTE" &&  <CustomButton $variant="warning" onClick={handleOpenModalRefutar}>Refutar</CustomButton>}
          {typeProfile === 2 && data.status === "DEFERIDO" && <CustomButton onClick={handleOpenModalAtivarCertificado}>Avaliar</CustomButton>}
          {typeProfile === 3 && <CustomButton onClick={handleOpenModalAvaliar}>Avaliar</CustomButton>}
          {typeProfile === 2 && <CustomButton onClick={handleOpenModalAvaliar}>Avaliar</CustomButton>}
          <CustomButton $variant="cancel" onClick={backPage}>
            Sair
          </CustomButton>
        </div>
        <ModalGenerico
          isOpen={isModalOpen}
          text='Deseja confirmar as informações contidas neste certificado? Após a confirmação, não será mais possível editá-las. Se todas as informações estiverem corretas, clique em "Confirmar". Caso contrário, clique em "Cancelar".'
          onCancel={handleCloseModal}
          textoOk="Confirmar"
          textoCancelar="Cancelar"
          onProceed={conferir}
        />

        {/* Modal para refutar o certificado */}
        <ModalRefutar
          isOpen={isModalOpenRefutar}
          onCancel={handleCloseModalRefutar}
          onConfirm={refutar}
        />

        {/* Modal para invalidar o certificado */}
        <ModalInvalidar
          isOpen={isModalOpenInvalidar}
          onCancel={handleCloseModalInvalidar}
          onConfirm={avaliar}
        />

        {/* Modal para avaliar o certificado */}
        <ModalAvaliacao
          isOpen={isModalOpenAvaliar}
          onCancel={handleCloseModalAvaliar}
          onConfirm={avaliar}
          status={data['status']}
        />

        {/* Modal para avaliar o certificado */}
        <ModalAtivarCertificado
          isOpen={isModalAtivarCertificado}
          onCancel={handleCloseModalAtivarCertificado}
          onConfirm={ativar}
          status={data['status']}
        />
      </Container>
    );
  }
};

export default CertificadoDados;
