import React, { useState } from 'react';
import './modalGenerico.css';
import CustomButton from 'Components/button/Button';
import { Notify, ToastType } from 'Components/notify/notify';

import motivoIndeferimento from "fakeData/motivosIndeferimento.json"

const ModalReprovar = ({ isOpen, onCancel, onConfirm, text }) => {
  const [motivo, setMotivo] = useState('');
  const [anexoMotivo, setAnexoMotivo] = useState('')
  const [anexoConvertido,setAnexoConvertido] = useState('')
  const [motivoError, setMotivoError] = useState(false); // Estado para validar o motivo
  const [anexoMotivoError, setAnexoMotivoError] = useState(false); 
  const [base64, setBase64] = useState('');
  const [acaoError, setAcaoError] = useState(false); // Estado para validar o select

  const handleChange = (e) => {
    setAnexoMotivo(e.target.files[0])
    setAnexoMotivoError(false)
  };

  const handleMotivoChange = (event) => {
    setMotivo(event.target.value);
    setMotivoError(false);
  };

  const handleConfirm = (motivo, base64String) => {
    onConfirm(motivo, base64String)
  };

  const handleConfirmClick = () => {
    let flag = 0;
    if(!motivo){
        console.log("TESTE")
        setMotivoError(true); // Exibir erro se motivo não for selecionado ao indeferir
        Notify({ message: `A adição de um motivo é obrigatória para realizar a reprovação!`, type: ToastType.Error, duration: 3000 });
        flag = 1;
    }
    if(!anexoMotivo){
        setAnexoMotivoError(true); // Exibir erro se motivo não for selecionado ao indeferir
        Notify({ message: `A adição de um anexo do motivo é obrigatória para realizar a reprovação!`, type: ToastType.Error, duration: 3000 });
        flag = 1;
    } else{
      const file = anexoMotivo;
      console.log("TIPO DO ARQUIVO",file.type)
      console.log("ARUIVO",anexoMotivo)
      if (file.type !== 'application/pdf') {
          Notify({
              message: `O Anexo da reprovação deve estar no formato PDF!`,
              type: ToastType.Error,
              duration:3000,
          });
          flag = 1;
          }
      
          if (file.size > 5 * 1024 * 1024) {
          Notify({
              message: `O Anexo da reprovação não pode ser maior que 5 MB!`,
              type: ToastType.Error,
              duration:3000,
          });
          flag = 1;
          }
      
          if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result.split(',')[1];
              
              // Chame handleConfirm com a string base64 diretamente
              if (flag === 0) {
                handleConfirm(motivo, base64String);
              }
            };
            reader.readAsDataURL(file);
            return; // Aguarde a leitura do arquivo antes de continuar
          }
    }

    if (flag == 1){
      return;
    }else{
      setMotivoError(false);
      setAcaoError(false);
      handleConfirm();
    }
    
  };
  
  const fechar = () =>{
    setMotivoError(false);
    setAnexoMotivoError(false);
    setAcaoError(false);
    setMotivo('');
    onCancel();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className='texto2'>
        {text}
        </p>
        <div className="motivo-select">
            <label className='opcao'>Motivo:</label>
            <textarea
              maxLength="255"
              name="justificativa"
              rows="4"
              value={motivo}
              onChange={handleMotivoChange}
              required
            />
            <label className='opcao'>Anexo Motivo:</label>
            <input
              type="file"
              name="anexoJustificativa"
              className="file-input"
              accept=".pdf"
              onChange={handleChange}
              required
            />
            {motivoError && (<p className="error-text" style={{ color: 'red' }}>Motivo é obrigatório.</p>)}
            {anexoMotivoError && (<p className="error-text" style={{ color: 'red' }}>Anexo do motivo é obrigatório.</p>)}
        </div>
        <CustomButton onClick={handleConfirmClick}>Reprovar</CustomButton>
        <CustomButton $variant="cancel" onClick={fechar}>Cancelar</CustomButton>
      </div>
    </div>
  );
};

export default ModalReprovar;