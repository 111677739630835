import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Notify, ToastType } from "Components/notify/notify";

import CertificadoForm from "../CadastrarCertificado/CertificadoForm";
import { useUserContext } from "contexts/UserContext";
import { useDataContext } from "contexts/DataContext";

const EditarCertificado = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const { mutationPutCertificado, setCertificadoData, docCertificados } = useDataContext();
  const data = location.state?.data;
  const userSelecionado = location.state?.users;
  const { user, typeProfile } = useUserContext();
  const path = "/pendentes";

  useEffect(() => {
    setCertificadoData(data);
    if (data) {
        setFormData({
        id: data.id,
        nome: data.nome,
        dataInicio: data.dataInicio,
        dataFim: data.dataFim,
        instituicao: data.instituicao,
        tipoQualificacao: data.tipoQualificacao,
        cargaHoraria: data.cargaHoraria,
        certificado: undefined,
        observacao: data.observacao,
        status: data.status,
        motivo: data.motivo,
        ativo: data.ativo,
        vinculoId: data.vinculoId,
        pessoaSessao: typeProfile,
        dataCadastro: data.dataCadastro,
        dataConferencia: data.dataConferencia,
        quemConferiu: data.quemConferiu,
        dataDeferimentoOuIndeferimento: data.dataDeferimentoOuIndeferimento,
        quemDeferiuOuIndeferiu: data.quemDeferiuOuIndeferiu,
        validacaoOnline: data.validacaoOnline,
        linkValidacao: data.linkValidacao,
        resposta: data.resposta,
        regional: data.regional,
        pessoa: data.pessoa,
      });
    }
  }, [data]);

  useEffect(() => {
    if (userSelecionado === undefined) {
      navigate(path);
    }
    
  }, [userSelecionado, navigate, data]);

  if (userSelecionado === undefined) {
    return null;
  }

  // Usa uma Promise para aguardar a leitura do arquivo
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    let certificadoAtualizado;
    if(formData.certificado === undefined){  
      try {
        certificadoAtualizado = {
          ...formData,
          certificado: docCertificados,
        };
      }
      catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      const file = formData.certificado;

      // Verificar se o Arquivo é um pdf
      if (file.type !== 'application/pdf') {
        Notify({
          message: `O Anexo do recurso deve estar no formato PDF!`,
          type: ToastType.Error,
          duration:3000,
        });
        return;
      }
      
      // Verificar se o arquivo tem menos que 5 MB
      if (file.size > 5 * 1024 * 1024) {
        Notify({
          message: `O Anexo do Recurso não pode ser maior que 5 MB!`,
          type: ToastType.Error,
          duration:3000,
        });
        return;
      }

      try {
        const base64 = await getBase64(file);
  
        certificadoAtualizado = {
          ...formData,
          vinculoId: parseInt(formData.vinculoId, 10),
          dataInicio: new Date(formData.dataInicio).toISOString(),
          dataFim: new Date(formData.dataFim).toISOString(),
          certificado: base64,
        };

      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
    try {
      let role = "SERVIDOR";
      if (typeProfile === 1){
        role = "RH"
      }
      await mutationPutCertificado.mutateAsync({data:certificadoAtualizado, role:role});
    } catch (error) {
      console.error("Erro ao editar certificado!", error);
    }
  };

  return (
    <CertificadoForm
      formData={formData}
      setFormData={setFormData}
      user={user}
      handleSubmit={handleSubmit}
      titulo="Editar Certificado"
      isEditar={true}
      data={data}
    />
  );
};

export default EditarCertificado;
