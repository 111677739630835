import { Notify, ToastType } from "../Components/notify/notify";

/**
 * Busca a lista de contagem de certificados.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Object>} - A lista de certificados.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getCertificadosCount = async (token) => {

  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/certificado/count/regional`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });
    throw new Error(errorMessage);
  }
  const result = await response.json();
  return result;
};

/**
 * Busca a lista de contagem de certificados de uma regional.
 * @param {string} token - O token de autorização.
 * @param {number} idRegional - O token de autorização.
 * @returns {Promise<Object>} - A lista de certificados.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getCertificadosCountRegional = async (token, idRegional) => {

  if (idRegional !== 'null' && idRegional !== null  && idRegional !== undefined){
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/certificado/count/regional?regional=${idRegional}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      Notify({
        message: `${errorMessage}`,
        type: ToastType.Error,
      });
      throw new Error(errorMessage);
    }
    const result = await response.json();
    return result;
  }
};

/**
 * Busca um certificado certificados.
 * @param {string} token - O token de autorização.
 * @returns {Promise<number>} - Um certificado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getCertificadosCountUserId = async (token, userId) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/certificado/count/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca a lista de certificados.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - A lista de certificados.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getCertificados = async (token, local) => {
  if (local === 'null' || local === null || local === undefined) {
    return null;
  }
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/certificado/regional/${local}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  const result = await response.json();
  return result;
};

/**
 * Busca um certificado certificados.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - Um certificado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getCertificadosUserId = async (token, userId) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/certificado/pessoa/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }
  const result = await response.json();
  return result;
};

/**
 * Deleta um certificado.
 * @param {string} token - O token de autorização.
 * @param {string} id - O ID do certificado a ser deletado.
 * @returns {Promise<Object|null>} - O resultado da deleção ou null se a resposta estiver vazia.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const deleteCertificado = async (token, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/certificado/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Remoção de certificado bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  const responseData = await response.text();
  return responseData ? JSON.parse(responseData) : null;
};

/**
 * Cria um novo certificado.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados do novo certificado.
 * @returns {Promise<Object>} - Os dados do certificado criado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const postCertificado = async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/certificado`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({ message: `Cadastro bem-sucedido!`, type: ToastType.Success });
  }

  return response.json();
};

/**
 * Atualiza um certificado.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados de certificado.
 * @returns {Promise<Object>} - Os dados de certificado atualizado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putCertificado= async (token, data, role) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/certificado/editar/${role}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Alteração de certificado bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Ativação de Certificado Deferido.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados para ativação.
 * @returns {Promise<Object>} - Os dados de certificado atualizado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putAtivarCertificado= async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/certificado/ativar`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Ativação de certificado bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};


/**
 * Busca dados de um certificado.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - Busca os dados de um certificado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getDocCertificado = async (token, data) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/certificado/imagem/${data.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }
  const result = await response.text();
  return result;
};

/**
 * Atualiza a conferência de um certificado.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados de certificado.
 * @returns {Promise<Object>} - Os dados de conferência de um certificado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const putAcaoCertificado = async (token, data, conferencia) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/certificado/${data.id}/acao`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(conferencia),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `Erro ao realizar ação no certificado! Detalhes: ${errorMessage}`, 
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Ação no certificado realizada com sucesso! Detalhes da ação: ${JSON.stringify(conferencia.acao)}`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

/**
 * Busca dados do vinculo.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - Busca os dados do vinculo
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getVinculos = async (token, vinculosId) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/vinculo/${vinculosId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

/**
 * Busca dados do vinculo.
 * @param {string} token - O token de autorização.
 * @returns {Promise<Array>} - Busca os dados do vinculo
 * @throws Lançará um erro se a requisição HTTP falhar.
 */
export const getPessoaVinculo = async (token, vinculoId) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pessoa/vinculoId/${vinculoId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }
  const result = await response.json();
  return result;
};

/**
 * Cria um novo certificado.
 * @param {string} token - O token de autorização.
 * @param {Object} data - Os dados do novo certificado.
 * @returns {Promise<Object>} - Os dados do certificado criado.
 * @throws Lançará um erro se a requisição HTTP falhar.
 */

export const getRecursosCountUserId = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/recurso/count`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  return response.json();
};

export const getRecursos = async (token, local) => {
  if (local === 'null' || local === null || local === undefined) {
    return null;
  }
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/recurso/regional/${local}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  const result = await response.json();
  return result;
};


export const getRecursoBytes = async (token, recursoId) => {
  if (recursoId === 'null' || recursoId === null || recursoId === undefined) {
    return null;
  }
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/recurso/bytes/${recursoId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }

  const result = await response.json();
  return result;
};

export const getRecursosUserId = async (token, userId) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/recurso/pessoa/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  }
  const result = await response.json();
  return result;
};

export const putAcaoRecurso = async (token, data) => {
  console.log("CERTIFICADO", data.certificado)
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/recurso/${data.certificado}/acao`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({
      message: `Ação de recurso bem-sucedida!`,
      type: ToastType.Success,
    });
  }

  return response.json();
};

export const postRecurso = async (token, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/recurso/${data.certificado}/recurso`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const errorMessage = await response.text();
    Notify({
      message: `${errorMessage}`,
      type: ToastType.Error,
    });

    throw new Error(errorMessage);
  } else {
    Notify({ message: `Cadastro bem-sucedido!`, type: ToastType.Success });
  }

  return response.json();
};
