import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginImage from './Assets/certifica 1.svg';
import seduclogo from './Assets/seduc_digital.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import { Notify, ToastType } from 'Components/notify/notify';
import CustomButton from 'Components/button/Button';
import { useUserContext } from 'contexts/UserContext';
import Footer from 'Components/footer/Footer';
import './styles.css';
import { isNull, isNumber } from 'pdfmake/src/helpers';

// Componente de Login
const Login = () => {
  const navigate = useNavigate();
  const { setUser, setToken, typeProfile, setTypeProfile } = useUserContext();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    login: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = DOMPurify.sanitize(value);
    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const data = await response.json();
        Notify({ message: `Login aceito!`, type: ToastType.Success });

        const token = data.token;

        var perfil = data.pessoa;

        var roles = data.roles.replace("[","").replace("]","")
        var nroles = data.nroles.replace("[","").replace("]","")

        perfil['tipo'] = roles.split(", ");
        perfil['tipoIds'] = nroles.split(", ");
        perfil['nregional'] = data.nregional
        perfil['regional'] = data.regional

        setUser(perfil)
        setToken(token)

        navigate("/perfil");
      } else {
        const errorData = await response.json();
        Notify({ message: `Login falhou: ${errorData.message}`, type: ToastType.Error });
      }
    } catch (error) {
      console.error('Erro inesperado:', error);
      Notify({ message: `Ocorreu um erro inesperado durante o login.`, type: ToastType.Error });
    }
  };
  console.log(typeProfile)
  if (!isNull(typeProfile) || isNumber(typeProfile)){
    setTypeProfile()
    localStorage.removeItem('tipoPerfil');
  }

  return (
    <div className="login-background">
      <div className="login-container">
        <div className="login-image">
          <img src={loginImage} alt="Imagem de Login" />
        </div>
        <div className="login-form">
          <img src={seduclogo} alt="Imagem de seduc" />
          <h2>CERTIFICA - LOGIN</h2>
          <form className='form-container' onSubmit={handleSubmit}>
            Email:
            <br />
            <label>
              <input type="text" name="login" value={formData.login} onChange={handleChange} />
            </label>
            <br />
            Senha:
            <br />
            <label className="password">
              <input type={showPassword ? "text" : "password"} name="password" value={formData.password} onChange={handleChange} />
              <button type="button" className="eye-button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </label>
            <br />
            <CustomButton style={{ marginBottom: '3px' }} type="submit">Entrar</CustomButton>
          </form>
          <span>
            <Link to="/" className='link-form'>Primeiro acesso</Link>
            <span className="separator">|</span>
            <Link to="/" className='link-form'>Esqueci minha senha</Link>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
